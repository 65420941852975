import React, { useState, useEffect } from "react";
import HPBXService from "../services/HPBXService";
import { Avatar, Flex, Text, Button, Loader, Menu, menuAsToolbarBehavior } from "@fluentui/react-northstar";
import { useSelector, useDispatch } from 'react-redux';
import { MoreIcon } from '@fluentui/react-icons-northstar';

import { updateHPBXQueue } from '../actions';

export const QueueRailItem = ({ queue }) => {

    const profile = useSelector(state => state.user.userProfile);
    const [error, setError] = useState("");

    const showAllQueues = useSelector(state => state.settings.showAllQueues);

    //const [available, setAvailable] = useState('0');
    const [destinationId, setDestinationId] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {

        let id = profile.hpbx.find(h => h.server == queue.server);

        if (id && queue.agents) {

            let me = queue.agents.find(a => a.dnumber === id.telephoneLines[0]);

            if (me) {
                setDestinationId(me.id);
            }
        }

    }, []);


    async function setAvailablity(avail) {
        setError("")
        if (destinationId) {

            HPBXService.setAvailablity(queue.server, destinationId, avail)
                .then(res => {
                    dispatch(updateHPBXQueue({ id: queue.id, server: queue.server, available: avail }))
                    // console.log("GOT BACK:: ", res)
                })
                .catch(err => {
                    setError("Unabled to update")
                    console.log("GOT BACK AN ERROR:", err)
                });
        }
    }

    function generateMenu() {
        const items = [
            {
                icon: (
                    <MoreIcon
                        {...{
                            outline: true,
                        }}
                    />
                ),
                key: `${queue.id}-menu`,
                'aria-label': 'More options',
                indicator: false,
                menu: {
                    items: [
                        {
                            key: `${queue.id}-auto`,
                            content: 'automatic',
                            onClick: () => setAvailablity(1)
                        },
                        {
                            key: `${queue.id}-manual`,
                            content: 'manual',
                            onClick: () => setAvailablity(0)

                        }
                    ],
                },
            }
        ];

        return items;
    }

    return (queue.loggedIn || showAllQueues === 'true' ? (
        <div className="row p-2 rail-item">
            <Flex gap="gap.small">
                <Avatar size="medium" name={queue.name} />
                <Flex.Item grow>
                    <Flex column gap="gap.small" vAlign="stretch">
                        <Text content={queue.name} size="bold" />
                        {
                            queue.loggedIn && <Text content={queue.available === '1' || queue.available === 1 ? "(Automatic)" : "(Manual)"} />
                        }
                        {
                            !queue.loggedIn ? <Text content="(Not Logged in)" /> : null
                        }
                    </Flex>
                </Flex.Item>

                <Flex.Item push>
                    {
                        queue.loggedIn && (
                            <>
                                <Menu
                                    defaultActiveIndex={0}
                                    items={generateMenu()}
                                    iconOnly
                                    accessibility={menuAsToolbarBehavior}
                                    aria-label="options"
                                />
                            </>
                        )}
                </Flex.Item>
            </Flex>
        </div>
    ) : null);
}
