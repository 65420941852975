import { UPDATE_GROUPS } from '../actions';

const initialState = {
    groups: []
}

export default function groupsReducer(state, action) {
    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case UPDATE_GROUPS:
            state.groups = action.payload;
            break;
        default:
            break;
    }

    return state
}