import userProfileReducer from './userProfile';
import queueReducer from './queue';
import { combineReducers } from 'redux';
import hpbxReducer from './hpbx';
import leftRailReducer from './leftRail';
import settingsReducer from "./settings";
import usersReducer from './users';
import groupsReducer from './groups';
//import contactsReducer from './contacts';
import parkedReducer from './parked';
import activeCallsReducer from './activeCalls';
import unansweredCallsReducer from './unansweredCalls';
import callbackReducer from './callbacks';

const allReducers = combineReducers({
    user: userProfileReducer,
    queue: queueReducer,
    hpbx: hpbxReducer,
    leftRail: leftRailReducer,
    settings: settingsReducer,
    users: usersReducer,
    groups: groupsReducer,
    //contacts: contactsReducer,
    parked: parkedReducer,
    activeCalls: activeCallsReducer,
    unansweredCalls: unansweredCallsReducer,
    callbacks: callbackReducer
});

export default allReducers;