import { UPDATE_USERS, UPDATE_PROFILE_FAVORITE, UPDATE_HPBXONLY_USERS, UPDATE_HPBXONLY_USER_PRESENCE } from '../actions';

const hash = require('object-hash');

const initialState = {
    users: [],
    hpbxOnlyUsers: []
}


export default function usersReducer(state, action) {

    let oldUsers = "";
    let newUsers = "";

    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case UPDATE_USERS:
            //before updating users is any user different?
            oldUsers = hash(state.users);
            newUsers = hash(action.payload);


            if (oldUsers != newUsers) {
                state.users = action.payload;
            }
            break;
        case UPDATE_HPBXONLY_USERS:
            state.hpbxOnlyUsers = action.payload;
            break;
        case UPDATE_HPBXONLY_USER_PRESENCE:
            //payload is all the users in an array like

            /* active: "0"​​
            dnd: "0"​​
            id: "1414"​​
            name: "1286302"​​
            registered: "0" */

            oldUsers = hash([...state.hpbxOnlyUsers]);

            action.payload.forEach(u => {
                state.hpbxOnlyUsers.forEach(user => {

                    if (user.hpbx) {

                        user.hpbx.forEach(h => {
                            if (h.telephoneLines?.length && u.name == h.telephoneLines[0]) {

                                if (!user.presence) {
                                    user.presence = { availability: "Offline" };
                                }

                                if (u.registered === 1 || u.registered === "1" || u.registered === true) user.presence.availability = "Available"; //if any line is online you are online
                                if (u.dnd === 1 || u.dnd === "1") user.presence.availability = "DoNotDisturb";
                                if (u.active === 1 || u.active === "1") user.presence.availability = "Busy";
                            }
                        })
                    }
                })
            })


            newUsers = hash(state.hpbxOnlyUsers);

            if (oldUsers != newUsers) {

                state.hpbxOnlyUsers = [...state.hpbxOnlyUsers];
            }

            break;
        case UPDATE_PROFILE_FAVORITE:

            let favorites = [];
            state.users.forEach(u => {
                if (u.isFav) {
                    favorites.push(u.id);
                }
            });
            localStorage.setItem("favorites", JSON.stringify(favorites));

            state.users = [...state.users];
            break;
        default:
            break;
    }

    return state
}