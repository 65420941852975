import React, { useState, useEffect } from "react";
import App from "./App";
import { useGraph } from "./lib/useGraph";
import { WebSocketProvider } from '../contexts/WebSocketContext';
import { Welcome } from "./Welcome";
import { NoLoad } from "./NoLoad";
import { Loading } from "./Loading"

export default function Init() {

    const [errorCount, setErrorCount] = useState(0);
    const [content, setContent] = useState(<Loading></Loading>);

    //get your profile
    const { loading, error, data, reload } = useGraph(
        async (graph) => {
            let me = await graph.api("/me").get();
            return { me };
        }
    );


    useEffect(() => {
        if (error && !data) {
            console.log(JSON.stringify(error));

            if (errorCount == 0 && error.body) {
                console.log("BODY:::", error.body);
                let re = /(AADSTS\d+)/g;
                const errCodes = error.body.match(re);

                //https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (errCodes) {
                    switch (errCodes[0]) {
                        case "AADSTS65001":
                        case "AADSTS500011":
                            //this means we are the first time using this app 
                            //instead of just doing reload I am going to load the initial screen
                            setContent(<Welcome reload={reload}></Welcome>);
                            break;

                    }

                    setErrorCount(errorCount + 1);

                }

            }
            else {
                if (error.code == 'ErrorWithCode.InternalError') {
                    console.log("Maybe not running in teams??")
                    setContent(<NoLoad message="This App only works inside of Teams, if you are inside of teams please reload and try again"></NoLoad>);
                }
                else {
                    setContent(<NoLoad reload={reload} message="Looks like we can't gain access to the permissions needed, please contact your office 365 admin"></NoLoad>);
                }
            }
        }
        else if (data) {
            setContent((
                <WebSocketProvider>
                    < App />
                </WebSocketProvider >
            ));
        }
    }, [error, data]);


    return content;
}