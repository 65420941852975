import React, { useState } from "react";
import HPBXService from "../services/HPBXService";
import { Button, Segment, Input, Text, Alert } from "@fluentui/react-northstar";

const FAX = () => {

    const [src, setSrc] = useState("");
    const [dest, setDest] = useState("");
    const [cover, setCover] = useState("");
    const [fax, setFax] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");

    const [response, setResponse] = useState("");
    const [error, setError] = useState("");

    async function sendFax() {

        HPBXService.sendFax(src, dest, confirmEmail, cover, fax)
            .then(res => {
                console.log(res)
                setResponse("Your FAX has been sent")
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)
                setResponse("Unable to send FAX")
            });
    }

    const saveToBase64 = async (event, setFunc) => {
        let f = event.target.files[0];
        try {
            let fileAsB64 = await toBase64(f);
            setFunc(fileAsB64);
        }
        catch (err) {
            setError("Couldn't read your file!")
        }

    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
        }
        reader.onerror = error => reject(error);
    });

    function validatePhone(p) {

        p = p.replace(/[^\d]/g, "");

        setDest(p);
    }

    return (
        <>
            {response ?
                <Alert
                    content={response}
                    success
                    header=""
                    visible
                /> : null}

            {error ?
                <Alert
                    content={error}
                    danger
                    header=""
                    visible
                /> : null}

            <div>
                <div className="col-12">
                    <Text content="File to send*" />
                    <input type="file" name="fax" onChange={(e) => saveToBase64(e, setFax)} />
                </div>
                <div className="col-12">
                    <Text content="Cover page" />
                    <input type="file" name="cover" onChange={(e) => saveToBase64(e, setCover)} />
                </div>
                <div className="col-12">
                    <Input clearable label="Destination" required value={dest} onChange={(e) => validatePhone(e.target.value)} />
                </div>
                <div className="col-12">
                    <Input clearable label="Send confirmation email to" required value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
                </div>
                <div class="d-flex justify-content-center">
                    <Button primary content="Send" onClick={sendFax} />
                </div>
            </div>
        </>
    );
}

export default FAX;
