import React, { useState, useEffect } from "react";
import { Input, Flex, Dropdown, Button, Dialog, useFluentContext } from "@fluentui/react-northstar";
import { updateSelectedSMSContact } from "../actions";
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon } from '@fluentui/react-icons-northstar';
import HPBXService from "../services/HPBXService";
import { useHistory } from "react-router-dom";


export const CreateSMSContact = ({ open, setOpen, destination }) => {

    const dispatch = useDispatch();
    const theme = useFluentContext().theme;
    const history = useHistory();


    const [src, setSrc] = useState("");
    const [srcList, setSrcList] = useState([]);

    const [number, setNumber] = useState("");
    //const [open, setOpen] = useState(false);

    const profile = useSelector(state => state.user.userProfile);

    useEffect(() => {
        console.log("Destination is::", destination)
        if (destination) {
            setNumber(destination)
        }
    }, [destination]);

    useEffect(() => {
        // console.log("Get Src numbers");
        getSrcList();
    }, []);

    async function getSrcList() {

        let srcs = [];


        if (profile.hpbx.length) {

            for (let id of profile.hpbx) {
                srcs.push(...id.numbers.filter(n => n.length > 9 && n.startsWith("04")));
            }
        }
        setSrcList(srcs);
    }

    const closePopup = () => {
        createContact();
        setOpen(false)
    }

    //this will actually not create anything but sets contact to a contact that you can talk to like how talking to someone with an empty message list would be.
    const createContact = () => {
        dispatch(updateSelectedSMSContact({ local: src, remote: number }));

        //if page is not already messaging then navigate there.
        if (history.location !== "/messaging") {
            history.push("/messaging");
        }

    }

    function validatePhone(p) {
        p = p.replace(/[^\d]/g, "");
        setNumber(p);
    }


    return (
        <>
            <Dialog

                open={open}
                onOpenChange={(e, { open }) => setOpen(open)}
                confirmButton={{ content: "OK", disabled: (number.length < 10 || src.length < 9) ? true : false }}
                cancelButton="Cancel"
                onConfirm={closePopup}
                onCancel={() => { setOpen(false) }}
                styles={{ "minWidth": "410px" }}
                content={{
                    content:
                        <div className="d-flex flex-row justify-content-between flex-wrap">
                            <div>
                                <Dropdown
                                    items={srcList}
                                    placeholder="Select your Source Number"
                                    onChange={(e, selected) => setSrc(selected.value)}
                                    inverted
                                />
                            </div>
                            <div>
                                <Input inverted clearable labelPosition="inline" label="Destination" required value={number} onChange={(e) => validatePhone(e.target.value)} />
                            </div>
                        </div>,
                    'aria-label': 'Start a new conversation over SMS',
                }}
                trapFocus
            />
        </>
    )
}
