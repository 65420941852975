import { UPDATE_VOICEMAIL, UPDATE_QUEUES, UPDATE_QUEUE, UPDATE_SMSMESSAGES, UPDATE_SELECTED_SMS_CONTACT, ADD_SMSMESSAGES, UPDATE_CONTACTS } from '../actions';
const hash = require('object-hash');

const initialState = {
    queues: [],
    voicemail: [],
    smsMessageConversations: [],
    sms: {
        selectedContact: {}
    },
    contacts: []
}

export default function hpbxReducer(state, action) {
    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case UPDATE_QUEUES:
            state.queues = action.payload;
            break;
        case UPDATE_QUEUE:
            let queue = state.queues.find(q => q.server == action.payload.server && q.id == action.payload.id);
            if (queue) {
                queue.available = action.payload.available;
            }
            state.queues = [...state.queues]
            break;
        case UPDATE_VOICEMAIL:
            state.voicemail = action.payload;
            break;
        case UPDATE_SMSMESSAGES:
            //this now works as conversations - 10/08/2022

            state.smsMessageConversations = action.payload.map(c => {

                let contactMatch = undefined;

                for (let serverContactList of state.contacts) {

                    let contactList = serverContactList.contacts;
                    for (let group in contactList) {

                        if (contactList[group].length) {

                            contactMatch = contactList[group].find(cm => (cm.office_number == c.remote || cm.mobile_number == c.remote));
                        }

                        if (contactMatch) {
                            break;
                        }
                    }

                    if (contactMatch) {
                        break;
                    }
                }

                return {
                    ...c,
                    remoteName: contactMatch?.display_name || c.remote
                }
            });
            //console.log("AFTER UPDATE_SMSMESSAGES::", state.smsMessageConversations);
            break;
        case ADD_SMSMESSAGES:
            //Messages via socket come in raw, so convert to conversation.

            if (action.payload.texts && action.payload.texts.length) {

                //convert payload to conversation
                let messages = action.payload.texts.map(t => {
                    let local = t.snumber;
                    let remote = t.dnumber;
                    if (t.stype == "external") {
                        local = t.dnumber;
                        remote = t.snumber;
                    }

                    return {
                        message: { ...t },
                        local,
                        remote
                    }
                });

                //console.log("Messages::", messages)

                let needsReload = false;

                //add messages.
                messages.forEach(m => {
                    let c = state.smsMessageConversations.find(smc => smc.local == m.local && smc.remote == m.remote);

                    if (c) {
                        //console.log("Conversation Found::", c)
                        //this is a conversation that is known.
                        //check if the message is also known
                        let thisMsgHash = hash(m.message);

                        if (!c.messages.some(cm => { let h = hash(cm); return h === thisMsgHash })) {
                            //this is a new message add to conversation
                            c.messages.push(m.message);
                            needsReload = true;
                            //console.log("Conversation Updated::", c)
                        }
                    }
                    else {
                        //console.log("Conversation Notfound::")
                        //this is new
                        state.smsMessageConversations.push({
                            local: m.local,
                            remote: m.remote,
                            messages: [
                                m.message
                            ]

                        });
                        needsReload = true;
                    }


                });

                if (needsReload) {
                    state.smsMessageConversations = JSON.parse(JSON.stringify(state.smsMessageConversations));
                }
            }


            break;
        case UPDATE_SELECTED_SMS_CONTACT:
            //this now works as conversations - 10/08/2022
            //console.log("UPDATE_SELECTED_SMS_CONTACT", action.payload);
            state.sms = { selectedContact: action.payload };
            state = JSON.parse(JSON.stringify(state));
            break;
        case UPDATE_CONTACTS:
            state.contacts = action.payload;

            state.smsMessageConversations = state.smsMessageConversations.map(c => {

                let contactMatch = undefined;
                for (let serverContactList of state.contacts) {

                    let contactList = serverContactList.contacts;
                    for (let group in contactList) {

                        if (contactList[group].length) {
                            contactMatch = contactList[group].find(cm => (cm.office_number == c.remote || cm.mobile_number == c.remote));
                        }

                        if (contactMatch) {
                            break;
                        }
                    }

                    if (contactMatch) {
                        break;
                    }
                }

                return {
                    ...c,
                    remoteName: contactMatch?.display_name || c.remote
                }
            });

            //console.log("AFTER CONTACTS::", state.smsMessageConversations);
            break;
        default:
            break;
    }

    return state
}