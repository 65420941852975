import React from "react";
import spinner from '../assets/images/spinner.svg';
import "./Welcome.css";


export const Loading = () => {



    return (

        <main >
            <div class="container">
                <div className="row full-height">
                    <div className="d-flex flex-wrap align-items-center align-content-center text-center text-light">

                        <div className="full-width"><img src={spinner} /></div>

                    </div>
                </div>
            </div>
        </main >
    );
}