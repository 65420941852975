import React, { useState, useEffect } from "react";
import { Input, Flex, Dropdown, Button, Dialog, useFluentContext } from "@fluentui/react-northstar";
import { updateSelectedSMSContact } from "../actions";
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon } from '@fluentui/react-icons-northstar';
import HPBXService from "../services/HPBXService";
import { useHistory } from "react-router-dom";
import { updateContacts } from '../actions';


export const CreateContact = ({ open, setOpen, mobile_number, contact }) => {

    const dispatch = useDispatch();
    const theme = useFluentContext().theme;
    const history = useHistory();

    const [error, setError] = useState("");
    const [updating, setUpdating] = useState(false);

    const [id, setID] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [officeNumber, setOfficeNumber] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [groups, setGroups] = useState([]);

    const [groupsOptions, setGroupOptions] = useState([]);

    const contacts = useSelector(state => state.hpbx.contacts);

    const profile = useSelector(state => state.user.userProfile);

    useEffect(() => {
        if (mobile_number) {
            setMobileNumber(mobile_number);
        }
    }, [mobile_number]);

    useEffect(() => {
        if (contact) {
            setID(contact.id);
            setDisplayName(contact.display_name);
            setOfficeNumber(contact.office_number);
            setMobileNumber(contact.mobile_number);
            setGroups(contact.groups.map(g => g.name).filter(g => g !== "Contacts"));
        }
    }, [contact]);

    useEffect(() => {

        if (contacts && contacts.length) {
            let grOpts = [];

            for (let serverContactList of contacts) {

                let contactList = serverContactList.contacts;

                for (let g in contactList) {
                    grOpts.push(g);
                }
            }

            grOpts = grOpts.filter(g => g !== "Contacts");

            setGroupOptions(grOpts);


        }

    }, [contacts]);



    //this will actually not create anything but sets contact to a contact that you can talk to like how talking to someone with an empty message list would be.
    const createContact = async () => {

        setUpdating(true)

        let server = "";
        if (profile.hpbx.length) {

            server = profile.hpbx[0].server;

            //recommending only having the contact list on one enswitch
            //find which the server from the contact list returned.
            if (contacts && contacts.length) {
                for (let serverContactList of contacts) {
                    server = serverContactList.server;
                }
            }

            let g = groups.map(gr => ({ name: gr }))

            let c = { display_name: displayName, office_number: officeNumber, mobile_number: mobileNumber, groups: g };
            if (id) {
                c.id = id
            }

            HPBXService.saveContact(server, c)
                .then(res => {
                    //setResponse("Your SMS has been sent")
                    //getSMSMessages();

                    //get the contacts again.

                    HPBXService.getContacts()
                        .then(res => {
                            let data = res.data.data;
                            if (data.length) {
                                dispatch(updateContacts(data));
                            }
                            setOpen(false);
                            setUpdating(false);
                        })
                        .catch(err => {
                            console.log("GOT BACK AN ERROR:", err)
                            setUpdating(false);
                        });

                })
                .catch(error => {
                    if (error.response) {

                        if (error.response?.data?.responses && error.response?.data?.responses.length) {
                            setError(error.response.data.responses[0].key);
                        }

                        /* console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers); */
                    }
                    else {

                        setError("Unable to save contact");
                    }
                    setUpdating(false);
                });
        }

    }

    function validatePhone(p) {
        p = p.replace(/[^\d]/g, "");
        setOfficeNumber(p);
    }

    function validateMobilePhone(p) {
        p = p.replace(/[^\d]/g, "");
        setMobileNumber(p);
    }


    return (
        <>
            <Dialog
                open={open}
                onOpenChange={(e, { open }) => setOpen(open)}
                confirmButton={{ content: "OK", disabled: (updating || displayName.length < 3 || (mobileNumber.length < 9 && officeNumber.length < 3)) ? true : false }}
                cancelButton={{ content: "Cancel", disabled: (updating) ? true : false }}
                onConfirm={createContact}
                onCancel={() => { setOpen(false) }}
                styles={{ "minWidth": "410px" }}
                content={{
                    content:
                        <>
                            <div className="d-flex flex-row justify-content-between flex-wrap">
                                <div>
                                    <Input inverted clearable labelPosition="inline" label="Display Name" required value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                                </div>
                                <div>
                                    <Input inverted clearable labelPosition="inline" label="Office Number" required value={officeNumber} onChange={(e) => validatePhone(e.target.value)} />
                                </div>
                                <div>
                                    <Input inverted clearable labelPosition="inline" label="Mobile Number" required value={mobileNumber} onChange={(e) => validateMobilePhone(e.target.value)} />
                                </div>
                                <div>
                                    <Dropdown
                                        multiple
                                        value={groups}
                                        items={groupsOptions}
                                        placeholder="Select Groups"
                                        onChange={(e, selected) => setGroups(selected.value)}
                                        inverted
                                    />
                                </div>

                            </div>
                            <div className="d-flex flex-row justify-content-between flex-wrap">
                                {error && <div className='alert alert-danger'>{error}</div>}
                            </div>
                        </>,
                    'aria-label': 'Create a new contact',
                }}
                trapFocus
            />
        </>
    )
}
