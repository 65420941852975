import React, { useState, useEffect } from "react";
import { Avatar, Flex, Text, Button, Loader, Menu, menuAsToolbarBehavior } from "@fluentui/react-northstar";

import { SettingsIcon, AcceptIcon } from '@fluentui/react-icons-northstar'
import { useSelector, useDispatch } from 'react-redux';
import { updateShowDebug, updateShowQueues, updateShowUnregistered } from "../actions";
import metadata from '../metadata.json';


export const ProfileRail = () => {

    const profile = useSelector(state => state.user.userProfile);
    const showAllQueues = useSelector(state => state.settings.showAllQueues);
    const showDebugLog = useSelector(state => state.settings.showDebug);

    const showUnregistered = useSelector(state => state.settings.showUnregistered);

    const dispatch = useDispatch();

    useEffect(() => {
        //loadsettings
        let showAll = localStorage.getItem("showAllQueues");
        let showDebug = localStorage.getItem("showDebugLog");
        let showUnregistered = localStorage.getItem("showUnregistered");
        if (showAll) {
            dispatch(updateShowQueues(showAll))
        }
        if (showDebug) {
            dispatch(updateShowDebug(showDebug))
        }
        if (showUnregistered) {
            dispatch(updateShowUnregistered(showUnregistered))
        }
    }, [])

    useEffect(() => {
        console.log(showAllQueues)
    }, [showAllQueues])

    function generateSettingsMenu() {
        const items = [
            {
                icon: (
                    <SettingsIcon
                        {...{
                            outline: true,
                        }}
                    />
                ),
                key: `settings-menu`,
                'aria-label': 'More options',
                indicator: false,
                menu: {
                    items: [
                        {
                            key: `showQueues`,
                            icon: (showAllQueues !== 'true' ? <AcceptIcon {...{ outline: true, }} /> : null),
                            content: 'Only show logged in Queues',
                            onClick: () => {
                                if (showAllQueues === 'true') {
                                    localStorage.setItem("showAllQueues", 'false');
                                    dispatch(updateShowQueues('false'))
                                }
                                else {
                                    localStorage.setItem("showAllQueues", 'true');
                                    dispatch(updateShowQueues('true'))
                                }

                            }
                        },
                        {
                            key: `showUnregistered`,
                            icon: (showUnregistered === 'true' ? <AcceptIcon {...{ outline: true, }} /> : null),
                            content: 'Show Unregistered Phones',
                            onClick: () => {
                                if (showUnregistered === 'true') {
                                    localStorage.setItem("showUnregistered", 'false');
                                    dispatch(updateShowUnregistered('false'))
                                }
                                else {
                                    localStorage.setItem("showUnregistered", 'true');
                                    dispatch(updateShowUnregistered('true'))
                                }

                            }
                        },
                        {
                            key: `showDebug`,
                            icon: (showDebugLog === 'true' ? <AcceptIcon {...{ outline: true, }} /> : null),
                            content: 'Show debug log',
                            onClick: () => {
                                if (showDebugLog === 'true') {
                                    localStorage.setItem("showDebugLog", 'false');
                                    dispatch(updateShowDebug('false'))
                                }
                                else {
                                    localStorage.setItem("showDebugLog", 'true');
                                    dispatch(updateShowDebug('true'))
                                }

                            }
                        },
                        {
                            key: `version`,
                            content: `Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`
                        }
                    ],
                },
            }
        ];

        return items;
    }

    let Title = "";

    if (profile && profile.hpbx && profile.hpbx.length) {
        profile.hpbx.forEach
            (id => {
                if (id && id.telephoneLines) {
                    Title += "Ext: " + id.telephoneLines[0] + " Svr:" + id.server + "\n";
                }
            })
    }


    return (
        <>
            {profile && (
                <div className="row p-2 rail-item" title={Title}>

                    <Flex gap="gap.medium" space="between">
                        <Flex.Item>
                            <Flex>
                                <Avatar size="small" image={profile.photoUrl} name={profile.displayName} />
                            </Flex>
                        </Flex.Item>
                        <Flex.Item>
                            <Flex column>
                                <Text content={profile.displayName} size="small" />
                                {
                                    /* profile.hpbx && profile.hpbx.map(id =>
                                        <Text content={id && id.telephoneLines && "Ext: " + id.telephoneLines[0] + " Svr:" + id.server} size="smaller" />
                                    ) */
                                }

                            </Flex>
                        </Flex.Item>
                        <Flex.Item push>
                            <Menu
                                defaultActiveIndex={0}
                                items={generateSettingsMenu()}
                                iconOnly
                                accessibility={menuAsToolbarBehavior}
                                aria-label="settings"
                            />
                        </Flex.Item>
                    </Flex>

                </div>
            )}
        </>
    );
}
