import { TeamsUserCredential } from "@microsoft/teamsfx";
import axios from "axios";

let backendBaseURL = 'https://hpbx-teams-api.hpbx.net.au';

if (process.env.REACT_APP_STAGE === "UAT") {
    backendBaseURL = 'https://dev-hpbx-teams-api.hpbx.net.au';
}

const HPBXService = {
    getHPBXLink: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/v2/me`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getHPBXPeople: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/people`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getGroups: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/groups`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getContacts: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/contacts`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    saveContact: async (server, contact) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/contacts/save?server=${server}`,
            headers: await generateHeaders(),
            data: { contact }
        };
        return axios.request(axiosConfig);
    },
    getHPBXPresence: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/presence`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getHPBXQueues: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/queues`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getHPBXQueueAgents: async (server, queue) => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/queues/agents?server=${server}&queue=${queue}`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getHPBXQueueCalls: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/queues/active`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getHPBXMailbox: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/mailbox`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getHPBXMessage: async (message) => {
        const axiosConfig = {
            method: 'GET',
            //responseType: "arraybuffer",
            url: `${backendBaseURL}/message?message=${message}`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getTextMessages: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/text/messages`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getTextMessageConversations: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/messages/text/conversations`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    getLastReadTextMessages: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/messages/text/conversations/read`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    updateReadTextMessages: async (server, local, remote, last_read_timestamp) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/messages/text/conversations/read/update`,
            headers: await generateHeaders(),
            data: { server, local, remote, last_read_timestamp }
        };
        return axios.request(axiosConfig);
    },
    getOutcomes: async (server) => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/outcomes?server=${server}`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },
    wrapCall: async (server, callid, queue, outcome) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/call/wrap`,
            headers: await generateHeaders(),
            data: { server, callid, queue, outcome }
        };
        return axios.request(axiosConfig);
    },
    callPickUp: async (server, uniqueid, queue, callerid) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/callpickup`,
            headers: await generateHeaders(),
            data: { uniqueid: uniqueid, queue, callerid, server }
        };
        return axios.request(axiosConfig);
    },
    callback: async (server, id) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/callback`,
            headers: await generateHeaders(),
            data: { server, id }
        };
        return axios.request(axiosConfig);
    },
    completeCallback: async (server, id) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/callback/complete`,
            headers: await generateHeaders(),
            data: { server, id }
        };
        return axios.request(axiosConfig);
    },
    callTransfer: async (server, uniqueid, cnumber, channel) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/calltransfer`,
            headers: await generateHeaders(),
            data: { server, uniqueid: uniqueid, cnumber, channel }
        };
        return axios.request(axiosConfig);
    },
    hangupCall: async (server, uniqueid) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/callhangup`,
            headers: await generateHeaders(),
            data: { server, uniqueid }
        };
        return axios.request(axiosConfig);
    },
    makeCall: async (server, cnumber, ctype) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/calls/make`,
            headers: await generateHeaders(),
            data: { server, cnumber, ctype }
        };
        return axios.request(axiosConfig);
    },
    createQueueEvent: async (callid, queue, callerid) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/queues/events/create`,
            headers: await generateHeaders(),
            data: { queue, callerid, callid }
        };
        return axios.request(axiosConfig);
    },
    getRecentCalls: async () => {
        const axiosConfig = {
            method: 'GET',
            url: `${backendBaseURL}/queuesync/recentcalls`,
            headers: await generateHeaders()
        };
        return axios.request(axiosConfig);
    },

    sendSMS: async (server, src, dest, message) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/sms`,
            headers: await generateHeaders(),
            data: { server, src, dest, message }
        };
        return axios.request(axiosConfig);
    },

    sendSMSBroadcast: async (server, src, destinations, message) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/smsbroadcast`,
            headers: await generateHeaders(),
            data: { server, src, destinations, message }
        };
        return axios.request(axiosConfig);
    },

    getSMSBroadcastDestinationGroups: async (server, src, destinations, message) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/smsbroadcast/destinationgroups`,
            headers: await generateHeaders(),
            data: { server, src, destinations, message }
        };
        return axios.request(axiosConfig);
    },

    sendFax: async (src, dest, email, cover, data) => {
        const axiosConfig = {
            method: 'POST',
            url: `${backendBaseURL}/fax`,
            headers: await generateHeaders(),
            data: { src, dest, email, cover, data }
        };
        return axios.request(axiosConfig);
    },
    setAvailablity: async (server, id, available) => {
        const axiosConfig = {
            method: 'PUT',
            url: `${backendBaseURL}/queues/destination/${id}`,
            headers: await generateHeaders(),
            data: { available, server }
        };
        return axios.request(axiosConfig);
    },

}

export default HPBXService;


//returns the header object
async function generateHeaders() {
    const credential = new TeamsUserCredential();
    //make into var
    const tokenObj = await credential.getToken("");
    //console.log(tokenObj);
    let token = tokenObj.token;
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': 'Bearer ' + token
    };

}


