import React from "react";
import { Flex, Text, useFluentContext, } from "@fluentui/react-northstar";
import { updateSelectedSMSContact } from "../actions";
import { useDispatch, useSelector } from 'react-redux';
import HPBXService from "../services/HPBXService";

export const SMSContact = ({ conversation }) => {

    const dispatch = useDispatch();
    const contact = useSelector(state => state.hpbx.sms.selectedContact);
    const theme = useFluentContext().theme;


    const updateSelectedSMSContactClick = () => {

        //this is now done in messaging
        /* let nowTS = Math.ceil((new Date()).getTime() / 1000);

        HPBXService.updateReadTextMessages(conversation.server, conversation.local, conversation.remote, nowTS)
            .then(res => {

            })
            .catch(err => {
                console.error("GOT BACK AN ERROR on setting history:", err);
            }); */

        dispatch(updateSelectedSMSContact({ local: conversation.local, remote: conversation.remote }));
    }


    let body = "";

    if (conversation.messages && conversation.messages.length) {
        body = conversation.messages[conversation.messages.length - 1].message;
        body = body.substring(0, 40) + "...";
    }

    let style = {};

    if (contact.local == conversation.local && contact.remote == conversation.remote) {
        style = {
            background: theme.siteVariables.colorScheme.default.background
        }
    }




    return (
        <div className="row p-2 rail-item" title={conversation.remote} style={style} onClick={() => { updateSelectedSMSContactClick() }}>

            <Flex gap="gap.medium" styles={({ theme: { siteVariables } }) => ({
                ":hover": {
                    backgroundColor: siteVariables.colorScheme.default.background
                }
            })}>
                <Flex column>
                    <Text content={"From: " + conversation.local + " To: " + (conversation.remoteName || conversation.remote)} size="small" weight="bold" />
                    {
                        (
                            conversation.messages && conversation.messages.length &&
                            <Text content={body} size="smaller" />
                        )

                        /* profile.hpbx && profile.hpbx.map(id =>
                            <Text content={id && id.telephoneLines && "Ext: " + id.telephoneLines[0] + " " + id.server} size="smaller" />
                        ) */
                    }

                </Flex>
                {
                    (
                        //don't render the unread if this is the selected conversation
                        //contact.local != conversation.local &&
                        conversation.unreadMessages ? <span class="badge bg-danger">{conversation.unreadMessages} unread</span> : null
                    )
                }
            </Flex>
        </div>
    )
}
