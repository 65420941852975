import React, { useState } from "react";
import { Avatar, Flex, Text, Button, Loader, Menu, menuAsToolbarBehavior } from "@fluentui/react-northstar";
import { PlayIcon } from '@fluentui/react-icons-northstar';
import HPBXService from "../services/HPBXService";
import format from 'date-fns/format'




const VMRailItem = ({ mail }) => {
    const [message, setMessage] = useState("");

    async function getMessage(msg) {

        console.log("DOWNLOAD AND PLAY MESSAGE:::", msg)
        HPBXService.getHPBXMessage(msg)
            .then(res => {
                let fileInfo = res.data.data;
                console.log(fileInfo)
                setMessage(URL.createObjectURL(new Blob([base64ToArrayBuffer(fileInfo.data)], { type: fileInfo.mimetype })))
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)
            });
    }

    function base64ToArrayBuffer(base64) {
        var binary_string = atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    return (mail && (<div className="row p-2 rail-item">
        <Flex gap="gap.small">
            <Flex.Item grow>
                <Flex column gap="gap.small" vAlign="stretch">
                    <Text content={mail.callerid} size="bold" />
                    <Text weight="small">Time {format(new Date(mail.time * 1000), "hh:mma dd/MM/yyyy")}</Text>
                    <Text size="small">Duration: {mail.duration}</Text>

                </Flex>
            </Flex.Item>

        </Flex>
        <div className="row">
            <div className="col-12">
                {!message && <Button fluid onClick={() => getMessage(mail.name)} ><PlayIcon /></Button>}
                {message &&
                    <audio id={mail.name} controls="controls" style={{ width: "100%" }}>
                        <source src={message} />
                    </audio>}
            </div>
        </div>
    </div >
    ));
};

export default VMRailItem;
