import { TeamsUserCredential, createMicrosoftGraphClient } from "@microsoft/teamsfx";
import hpbx from './HPBXService';
import { updateUsers, updateUserPresence, updateHPBXOnlyUserPresence } from '../actions';
import store from '../store';

let presenceInterval;
let presenceRunning = false;
let hpbxPresenceInterval;
let hpbxPresenceRunning = false;

const restartPresencePoller = () => {
    console.log("restartPresencePoller")
    if (presenceInterval) {
        clearInterval(presenceInterval);
    }

    if (hpbxPresenceInterval) {
        clearInterval(hpbxPresenceInterval);
    }

    presenceInterval = setInterval(() => pollPresence(), 5500);
    hpbxPresenceInterval = setInterval(() => pollHPBXPresence(), 6500);

}


const pollPresence = async () => {

    if (presenceRunning) {
        console.log("presenceRunning was true exiting")
        return;
    }

    else {
        presenceRunning = true;
        //console.log("pollPresense")

        const profiles = JSON.parse(JSON.stringify(store.getState().users.users)); //make a copy

        let ids = profiles.map(p => p.id);

        if (ids.length) {

            let credential = new TeamsUserCredential();

            let graph = createMicrosoftGraphClient(credential, [".default"]);
            const presence = await graph.api("/communications/getPresencesByUserId").post({ ids });

            presence.value.forEach(pres => {

                if (pres.availability !== "PresenceUnknown") {
                    let p = profiles.find(p => p.id == pres.id);

                    if (p) {
                        p.presence = pres;
                    }
                }
            })

            //console.log("DONE GETTING Profiles NOW ::", profiles);


            store.dispatch(updateUsers([...profiles]));
            store.dispatch(updateUserPresence([...profiles]));

        }
        presenceRunning = false;
    }
}

const pollHPBXPresence = async () => {

    if (hpbxPresenceRunning) {
        console.log("presenceRunning was true exiting")
        return;
    }

    else {
        //console.log("pollHPBXPresense")
        hpbxPresenceRunning = true;
        hpbx.getHPBXPresence()
            .then(async (res) => {
                store.dispatch(updateHPBXOnlyUserPresence(res.data));
                hpbxPresenceRunning = false;
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR ON PRESENCE:", err)
                hpbxPresenceRunning = false;
            });
    }
}

export default { restartPresencePoller };