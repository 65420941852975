import { UPDATE_USER_PROFILE, UPDATE_USER_PROFILE_PRESENCE } from '../actions';

const initialState = {
    userProfile: {},
    presence: {}
}


export default function userProfileReducer(state, action) {

    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case UPDATE_USER_PROFILE:
            state.userProfile = { ...action.payload };
            break;
        case UPDATE_USER_PROFILE_PRESENCE:
            const me = action.payload.find(u => u.id == state.userProfile.id);
            if (me) {
                state.presence = me.presence;
            }
            break;
        default:
            break;
    }

    return state
}