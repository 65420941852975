
import React, { createContext, useState, useEffect, useRef } from 'react'
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useDispatch } from 'react-redux';
import { updateQueueLog, updateParkedCalls, updateActiveCalls, updateUnansweredCalls, updateCallbacks, addSMSMessages } from '../actions';
import { TeamsUserCredential } from "@microsoft/teamsfx";

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {

    const dispatch = useDispatch();
    const [error, setError] = useState("");

    const rwsRef = useRef(null);

    useEffect(() => {

        const options = {
            connectionTimeout: 1000,
            maxRetries: 10,
        };

        let rws;
        if (process.env.REACT_APP_STAGE === "UAT") {
            rws = new ReconnectingWebSocket('wss://uat-hpbx-teams-rt.hpbx.net.au:8443', [], options);
        }
        else {
            rws = new ReconnectingWebSocket('wss://hpbx-teams-rt-alb.hpbx.net.au', [], options);
        }

        rws.addEventListener('open', () => {
            console.log("RWS is open!");
            setError("");
            authenticate();

        });

        rws.addEventListener('close', () => {
            console.log("RWS is Closed!")
            setError("Connection Closed... Retrying");
        });

        rws.addEventListener('message', (message) => {
            //console.log(message.data)
            let msg = JSON.parse(message.data);
            if (msg.event === 'queue_log') {
                console.log("Updating QueueLog")
                dispatch(updateQueueLog(msg.data));
            }
            if (msg.event === 'update') {
                //add server into the message
                dispatch(updateParkedCalls({ server: msg.data.server, parked_calls: msg.data.parked_calls }));
                dispatch(updateActiveCalls({ server: msg.data.server, active_answered_calls: msg.data.active_answered_calls }));
                dispatch(updateUnansweredCalls({ server: msg.data.server, unanswered_calls: msg.data.unanswered_calls }));
                dispatch(updateCallbacks({ server: msg.data.server, callbacks: msg.data.callbacks }));

                dispatch(addSMSMessages({ server: msg.data.server, texts: msg.data.texts }));

                //dispatch(updateQueueLog(msg.data.queue_events));
                /* msg.data.queue_events.forEach(d => {
                    console.log("DISPATCHING", d)
                    dispatch(updateQueueLog(d));
                }); */
            }
        });

        rws.addEventListener('error', () => {
            console.log("an error occured")
        });

        rwsRef.current = rws;

        const authenticate = async () => {
            const credential = new TeamsUserCredential();
            const tokenObj = await credential.getToken("");
            let token = tokenObj.token;

            sendMessage({ event: 'authenticate', data: { token } })
        }

        const sendMessage = (message) => {
            rws.send(JSON.stringify(message));
        }

        return () => {
            rws.close();
        };
    }, []);



    let ws = {

    }


    return (

        <WebSocketContext.Provider value={ws}>
            {error && <div className='alert alert-danger'>{error}</div>}

            {children}

        </WebSocketContext.Provider>

    )

}