import { UPDATE_ACTIVE_CALLS } from '../actions';


const initialState = {
    //activeCalls: []
}

export default function activeCallsReducer(state, action) {
    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case UPDATE_ACTIVE_CALLS:

            if (!state[action.update.server]) {
                state[action.update.server] = [];
            }

            state[action.update.server] = [...action.update.active_answered_calls];

            state = JSON.parse(JSON.stringify(state));


            break;
        default:
            break;
    }

    return state
}