import React, { useState } from "react";
import hpbxLogo from '../assets/images/hpbx2020.svg';
import spinner from '../assets/images/spinner.svg';
import "./Welcome.css";


export const Welcome = ({ reload }) => {

    const [loading, setLoading] = useState(false);


    return (

        <main >
            <div className="welcome">
                <div class="container">
                    <div className="row full-height">
                        <div className="d-flex flex-wrap align-items-center align-content-center text-center text-light">

                            <div className="full-width"><h1>Welcome to HPBX for Teams</h1></div>
                            <div className="full-width"><img src={hpbxLogo} /></div>
                            {loading ?
                                <div className="full-width"><img width="100px" src={spinner} /></div>
                                :
                                <div className="full-width"><button type="button" class="btn btn-primary btn-lg" onClick={() => { setLoading(true); reload(); }}>Get Started</button></div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}