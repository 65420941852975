import React, { useState, useEffect } from "react";
import HPBXService from "../services/HPBXService";
import { Avatar, Card, Flex, Text, Button, Loader } from "@fluentui/react-northstar";
import { useSelector, useDispatch } from 'react-redux';

import { updateHPBXQueue } from '../actions';

export const QueueCard = ({ queue }) => {

    const profile = useSelector(state => state.user.userProfile);
    const [error, setError] = useState("");
    const [destinationId, setDestinationId] = useState(0);

    const dispatch = useDispatch();


    useEffect(() => {

        let id = profile.hpbx.find(h => h.server == queue.server);

        if (id && queue.agents) {

            let me = queue.agents.find(a => a.dnumber === id.telephoneLines[0]);

            if (me) {
                setDestinationId(me.id);
            }
        }

    }, []);

    async function setAvailablity(avail) {
        setError("")
        if (destinationId) {

            HPBXService.setAvailablity(queue.server, destinationId, avail)
                .then(res => {
                    dispatch(updateHPBXQueue({ id: queue.id, server: queue.server, available: avail }))
                    // console.log("GOT BACK:: ", res)
                })
                .catch(err => {
                    setError("Unabled to update")
                    console.log("GOT BACK AN ERROR:", err)
                });
        }
    }

    return (
        <div className="col-12 col-md-4 col-lg-3">
            <Card
                aria-roledescription="card queue"
                elevated
                inverted
                fluid
                styles={{ height: "max-content", margin: "0.5em 0" }}
            >
                <Card.Header styles={{ "margin-bottom": "0" }}>

                    {queue && (
                        <>
                            <Flex gap="gap.medium">
                                <Avatar size="medium" name={queue.name} />{" "}

                                <Flex column>
                                    <Text content={queue.name} size="bold" />
                                    <Text content={"Queue ID: " + queue.id} weight="small" />

                                </Flex>

                            </Flex>
                            <Flex>
                                <Text content={queue.loggedIn ? "logged in to this queue" : "You are not logged in"} weight="small" />
                            </Flex>
                            {
                                queue.loggedIn && (
                                    <Flex>
                                        <Button content="Automatic" {...(queue.available === '1' || queue.available === 1 ? { primary: true } : { secondary: false })} onClick={() => setAvailablity(1)}></Button>
                                        <Button content="Manual" {...(queue.available === '0' || queue.available === 0 ? { primary: true } : { secondary: false })} onClick={() => setAvailablity(0)}></Button>
                                    </Flex>)

                            }
                            {
                                error && (
                                    <Flex>
                                        <Text content={error} weight="small" />
                                    </Flex>)
                            }

                        </>
                    )}
                </Card.Header>
            </Card>
        </div>
    );
}
