export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_PRESENCE = "UPDATE_USER_PROFILE_PRESENCE";
export const UPDATE_QUEUE_LOG = "UPDATE_QUEUE_LOG";
export const UPDATE_QUEUE_CALLS = "UPDATE_QUEUE_CALLS";
export const UPDATE_QUEUES = "UPDATE_QUEUES";
export const UPDATE_QUEUE = "UPDATE_QUEUE";
export const UPDATE_RAIL = "UPDATE_RAIL";
export const UPDATE_VOICEMAIL = "UPDATE_VOICEMAIL";
export const UPDATE_SHOW_QUEUES = "UPDATE_SHOW_QUEUES";
export const UPDATE_SHOW_DEBUG = "UPDATE_SHOW_DEBUG";
export const UPDATE_SHOW_UNREGISTERED = "UPDATE_SHOW_UNREGISTERED";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const UPDATE_CONTACTS = "UPDATE_CONTACTS";

export const UPDATE_HPBXONLY_USERS = "UPDATE_HPBXONLY_USERS";
export const UPDATE_HPBXONLY_USER_PRESENCE = "UPDATE_HPBXONLY_USER_PRESENCE";
export const UPDATE_PROFILE_FAVORITE = "UPDATE_PROFILE_FAVORITE";
export const UPDATE_PARKED = "UPDATE_PARKED";

export const UPDATE_ACTIVE_CALLS = "UPDATE_ACTIVE_CALLS";

export const UPDATE_UNANSWERED_CALLS = "UPDATE_UNANSWERED_CALLS";
export const UPDATE_SMSMESSAGES = "UPDATE_SMSMESSAGES";
export const UPDATE_SELECTED_SMS_CONTACT = "UPDATE_SELECTED_SMS_CONTACT";

export const UPDATE_CALLBACKS = "UPDATE_CALLBACKS";

export const ADD_SMSMESSAGES = "ADD_SMSMESSAGES";

export function updateQueueLog(update) {
    return {
        type: UPDATE_QUEUE_LOG,
        update
    }
}

export function updateQueueCalls(update) {
    return {
        type: UPDATE_QUEUE_CALLS,
        update
    }
}

export function updateActiveCalls(update) {
    return {
        type: UPDATE_ACTIVE_CALLS,
        update
    }
}

export function updateUnansweredCalls(update) {
    return {
        type: UPDATE_UNANSWERED_CALLS,
        update
    }
}

export function updateParkedCalls(update) {
    return {
        type: UPDATE_PARKED,
        update
    }
}

export function updateCallbacks(update) {
    return {
        type: UPDATE_CALLBACKS,
        update
    }
}

export function updateUserProfile(payload) {
    return {
        type: UPDATE_USER_PROFILE,
        payload
    }
}

export function updateUserPresence(payload) {
    return {
        type: UPDATE_USER_PROFILE_PRESENCE,
        payload
    }
}



export function updateHPBXQueues(payload) {
    return {
        type: UPDATE_QUEUES,
        payload
    }
}

export function updateHPBXQueue(payload) {
    return {
        type: UPDATE_QUEUE,
        payload
    }
}

export function updateVoicemail(payload) {
    return {
        type: UPDATE_VOICEMAIL,
        payload
    }
}

export function updateSMSMessages(payload) {
    return {
        type: UPDATE_SMSMESSAGES,
        payload
    }
}

export function addSMSMessages(payload) {
    return {
        type: ADD_SMSMESSAGES,
        payload
    }
}



export function updateSelectedSMSContact(payload) {
    return {
        type: UPDATE_SELECTED_SMS_CONTACT,
        payload
    }
}




export function updateLeftRail(payload) {
    return {
        type: UPDATE_RAIL,
        payload
    }
}


export function updateShowQueues(payload) {
    return {
        type: UPDATE_SHOW_QUEUES,
        payload
    }
}

export function updateShowUnregistered(payload) {
    return {
        type: UPDATE_SHOW_UNREGISTERED,
        payload
    }
}

export function updateShowDebug(payload) {
    return {
        type: UPDATE_SHOW_DEBUG,
        payload
    }
}

export function updateUsers(payload) {
    return {
        type: UPDATE_USERS,
        payload
    }
}

export function updateGroups(payload) {
    return {
        type: UPDATE_GROUPS,
        payload
    }
}

export function updateContacts(payload) {
    return {
        type: UPDATE_CONTACTS,
        payload
    }
}


export function updateHPBXOnlyUsers(payload) {
    return {
        type: UPDATE_HPBXONLY_USERS,
        payload
    }
}

export function updateHPBXOnlyUserPresence(payload) {
    return {
        type: UPDATE_HPBXONLY_USER_PRESENCE,
        payload
    }
}

export function updateProfileFavorite(payload) {
    return {
        type: UPDATE_PROFILE_FAVORITE,
        payload
    }
}