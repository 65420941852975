import "./LeftRail.css";
import React, { useState, useEffect } from "react";
import HPBXService from "../services/HPBXService";
import { QueueRailItem } from "./QueueRailItem";
import { useSelector, useDispatch } from 'react-redux';
import { SettingsIcon, MenuIcon } from '@fluentui/react-icons-northstar';
import { SplitButton, Flex, Button, Accordion } from "@fluentui/react-northstar";



import { updateLeftRail, updateSMSMessages } from "../actions";
import { ProfileRail } from "./ProfileRail";
import VMRailItem from "./VMRailItem";
import Fax from "./Fax";
import { SMSContact } from "./SMSContact";
import { ProfileRailItem } from "./ProfileRailItem";
import { ContactRailItem } from "./ContactRailItem";
import { useHistory } from "react-router-dom";
import { CreateSMSContact } from "./CreateSMSContact";
import { CreateSMSBroadcast } from "./CreateSMSBroadcast";
import { CreateContact } from "./CreateContact";


export const LeftRail = () => {

    const history = useHistory();

    const dispatch = useDispatch();
    const leftRailMenu = useSelector(state => state.leftRail.menu);

    const queues = useSelector(state => state.hpbx.queues);
    const profiles = useSelector(state => state.users.users);
    const groups = useSelector(state => state.groups.groups);
    const contacts = useSelector(state => state.hpbx.contacts);

    const hpbxUsers = useSelector(state => state.users.hpbxOnlyUsers);

    const showUnregistered = useSelector(state => state.settings.showUnregistered);

    const [mail, setMail] = useState([]);

    const [showMenu, setShowMenu] = useState(true); //default open

    const smsMessageConversations = useSelector(state => state.hpbx.smsMessageConversations);
    const [readHistory, setReadHistory] = useState([]);

    useEffect(() => {
        getHPBXMailbox();
        getSMSMessages();
    }, [leftRailMenu]);


    useEffect(() => {

        setTimeout(() => {

            HPBXService.getLastReadTextMessages()
                .then(res => {
                    console.log(res.data);
                    setReadHistory(res.data.data);
                    //generateItems();
                })
                .catch(err => {
                    console.log("SMS READ HISTORY GOT BACK AN ERROR:", err);
                    //generateItems();
                });

        }, 1500);


    }, [smsMessageConversations]);


    useEffect(() => {
        console.log("Generating Profiles");
        genProfilesAccordion();

    }, [profiles, hpbxUsers]);

    useEffect(() => {
        //genContactsAccordion();
    }, [contacts]);



    async function getHPBXMailbox() {

        HPBXService.getHPBXMailbox()
            .then(res => {
                //console.log("MAILBOX GOT BACK ::", res);
                // console.log(res.data.data)
                setMail(res.data.data);
            })
            .catch(err => {
                console.log("MAILBOX GOT BACK AN ERROR:", err)
            });
    }

    async function getSMSMessages() {

        HPBXService.getTextMessageConversations()
            .then(res => {
                //console.log("SMS GOT BACK ::", res);
                //console.log(res.data.data)
                //setSMSMessages(res.data.data);
                dispatch(updateSMSMessages(res.data.data))
            })
            .catch(err => {
                console.log("SMS GOT BACK AN ERROR:", err)
            });

        /* HPBXService.getTextMessages()
            .then(res => {
                //console.log("SMS GOT BACK ::", res);
                //console.log(res.data.data)
                //setSMSMessages(res.data.data);
                dispatch(updateSMSMessages(res.data.data))
            })
            .catch(err => {
                console.log("SMS GOT BACK AN ERROR:", err)
            }); */
    }

    const genProfilesAccordion = () => {
        //console.log("Generating Profiles");
        //console.log("profiles::", profiles);
        //console.log("hpbxUsers::", hpbxUsers);

        const myTeamPanel = profiles.map(p => p.isFav ? <ProfileRailItem profile={p}></ProfileRailItem> : null);
        myTeamPanel.push(hpbxUsers.map(p => p.isFav ? <ProfileRailItem profile={p}></ProfileRailItem> : null));
        const teamsPanels = profiles.map(p => <ProfileRailItem profile={p}></ProfileRailItem>);
        const hpbxPanels = hpbxUsers.map(p => (p?.presence?.availability === "Offline" && showUnregistered !== 'true') || !p?.presence?.availability ? null : <ProfileRailItem profile={p}></ProfileRailItem>);

        let panels = [
            {
                key: 'myTeamLR',
                title: 'My Team',
                content: myTeamPanel,
            }
        ];

        //add the group names for now
        groups.forEach(g => {

            try {

                let data = JSON.parse(g.data);
                if (data.users) {

                    let content = [];
                    try {
                        data.users.forEach(u => {
                            //g.server == p.hpbx[...].server
                            //profiles.hpbx[...].id == u.id
                            content.push(...profiles.filter(p => p.hpbx.some(h => h.server === g.server && h.id == u.id))
                                .map(p => <ProfileRailItem profile={p}></ProfileRailItem>)
                            );
                        });
                    }
                    catch (err) {
                        console.error("Unabled to parse Teams users for group", g)
                    }

                    try {
                        data.users.forEach(u => {
                            //g.server == p.hpbx[...].server
                            //profiles.hpbx[...].id == u.id
                            content.push(...hpbxUsers.filter(p => !((p?.presence?.availability === "Offline" && showUnregistered !== 'true') || !p?.presence?.availability))
                                .filter(p => p.hpbx.some(h => h.server === g.server && h.id == u.id))
                                .map(p => <ProfileRailItem profile={p}></ProfileRailItem>));
                        });
                    }
                    catch (err) {
                        console.error("Unabled to parse HPBX users for group", g)
                    }

                    panels.push(
                        {
                            key: g.name + "LR",
                            title: g.name,
                            content,
                        }
                    )
                }
            }
            catch (err) {
                console.log("Parse Group error", err)
            }
        });

        panels.push(
            {
                key: 'teamLR',
                title: 'Teams',
                content: teamsPanels,
            });

        panels.push(
            {
                key: 'hpbxPhones',
                title: 'HPBX',
                content: hpbxPanels,
            });

        return <Accordion defaultActiveIndex={[0]} panels={panels} />
    }

    const [createContactOpen, setCreateContactOpen] = useState(false);
    const genContactsAccordion = () => {
        console.log("generating contacts")
        if (contacts && contacts.length) {

            let contactPanels = [];
            for (let serverContactList of contacts) {

                let contactList = serverContactList.contacts;
                let server = serverContactList.server;

                for (let g in contactList) {
                    if (contactList[g].length) {
                        let content = contactList[g].sort((a, b) => a.display_name.localeCompare(b.display_name)).map(c => <ContactRailItem server={server} contact={c} ></ContactRailItem>);

                        contactPanels.push({
                            key: g,
                            title: g,
                            content: content,
                        })
                    }
                }
            }

            return <>
                <CreateContact open={createContactOpen} setOpen={setCreateContactOpen}></CreateContact>
                <div className="row p-2 rail-item">
                    <Flex gap="gap.medium" hAlign="center">
                        <Button content="Add Contact" primary onClick={() => { setCreateContactOpen(true) }} />
                    </Flex>
                </div>

                <Accordion defaultActiveIndex={[0]} panels={contactPanels} /></>
        }
        else {
            return <></>;
        }

    }

    const [open, setOpen] = useState(false);
    const [createBroadcastOpen, setCreateBroadcastOpen] = useState(false);




    const genSMSAccordion = () => {

        let smsRail = [];

        smsRail.push(<CreateSMSContact open={open} setOpen={setOpen}></CreateSMSContact>);
        smsRail.push(<CreateSMSBroadcast open={createBroadcastOpen} setOpen={setCreateBroadcastOpen}></CreateSMSBroadcast>);
        smsRail.push(
            <div className="row p-2 rail-item">
                <Flex column gap="gap.medium" hAlign="center">
                    <Button content="Start a new conversation" primary onClick={() => { setOpen(true) }} />
                    <Button content="SMS Broadcast" primary onClick={() => { setCreateBroadcastOpen(true) }} />
                </Flex>
            </div>
        );


        //order the conversations newest to oldest
        smsRail.push(...smsMessageConversations.sort((a, b) => {

            if (!a?.messages?.length && !b?.messages?.length) {
                return 0; //both of these are older than max date so return equal
            }
            if (a?.messages?.length && !b?.messages?.length) {
                return -1; //a has messages and b doesn't so a is greater
            }
            if (!a?.messages?.length && b?.messages?.length) {
                return 1; //b has messages and a doesn't so b is greater
            }

            //Now both a and b have messages so check last message's receive of both
            return b.messages[b.messages.length - 1].time - a.messages[a.messages.length - 1].time;

        })
            .map(c => {
                //add a counter of new UNREAD messages
                let readTS = 1675317962; //this is around the time the feature was added so this is the 0 point
                if (readHistory.length) {
                    let read = readHistory.find(h => h.local == c.local && h.remote == c.remote);

                    if (read) {
                        readTS = read.last_read_timestamp;
                    }
                }

                c.unreadMessages = c.messages.filter(m => m.stype == 'external' && m.time > readTS).length;

                return c;
            })
            .map(c => <SMSContact conversation={c}></SMSContact>));

        return smsRail;
    }

    let profilesAccordion = genProfilesAccordion();

    let smsAccordion = genSMSAccordion();
    let contactsAccordion = genContactsAccordion();

    function routeTo(path) {
        history.push(path);
    }





    return (

        <>
            <div className="d-block d-md-none" >
                <Button icon={<MenuIcon />} text title="Menu" content="Menu" onClick={() => { setShowMenu(!showMenu) }} />
            </div>

            <div className={`${showMenu ? 'd-block menu' : 'd-none'} d-md-block col-md-4 col-lg-3 col-xxl-2 left-rail left-rail-box-shadow-overlay full-height overflow-auto`}>
                < ProfileRail ></ProfileRail >
                <div className="left-rail-header-v2 left-rail-header constant-border">
                    <>
                        <SplitButton

                            menu={[
                                {
                                    key: 'Team',
                                    content: 'Team',
                                    onClick: () => { dispatch(updateLeftRail("Team")); routeTo("/") }
                                },
                                {
                                    key: 'Contacts',
                                    content: 'Contacts',
                                    onClick: () => { dispatch(updateLeftRail("Contacts")); routeTo("/") }
                                },
                                {
                                    key: 'Queues',
                                    content: 'Queues',
                                    onClick: () => { dispatch(updateLeftRail("Queues")); routeTo("/") }
                                },
                                {
                                    key: 'Voicemail',
                                    content: 'Voicemail',
                                    onClick: () => { dispatch(updateLeftRail("Voicemail")); routeTo("/") }
                                },
                                {
                                    key: 'SMSHistory',
                                    content: 'SMS Messages',
                                    onClick: () => { dispatch(updateLeftRail("SMS Messages")); routeTo("/messaging") }
                                },
                                {
                                    key: 'Fax',
                                    content: 'Fax',
                                    onClick: () => { dispatch(updateLeftRail("Fax")); routeTo("/") }
                                },
                            ]}
                            button={{
                                content: leftRailMenu,
                                'aria-roledescription': 'splitbutton',
                                'aria-describedby': 'instruction-message',
                            }}
                            toggleButton={{
                                'aria-label': 'more options',
                            }}
                        />
                        <span
                            aria-hidden="true"
                            id="instruction-message"
                            style={{
                                opacity: 0,
                            }}
                        >click to open menu
                        </span>
                    </>


                </div>
                <div id="left-rail-list">
                    {leftRailMenu === "Team" && profilesAccordion}
                    {leftRailMenu === "Contacts" && contactsAccordion}
                    {leftRailMenu === "Voicemail" && mail.map(m => <VMRailItem mail={m}></VMRailItem>)}
                    {leftRailMenu === "Queues" && queues.map(q => <QueueRailItem queue={q}></QueueRailItem>)}
                    {leftRailMenu === "SMS Messages" && smsAccordion}
                    {leftRailMenu === "Fax" && <Fax></Fax>}
                </div>
            </div >

        </>


    )
};