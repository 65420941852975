import { UPDATE_QUEUE_LOG, UPDATE_QUEUE_CALLS } from '../actions';


const initialState = {
    queueLog: [],
    debugLog: [],
    calls: []
}

export default function queueReducer(state, action) {
    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case UPDATE_QUEUE_CALLS:

            //this is called when the queue pages reload and need to sync up calls that have happened before loading this page with the queue log

            let callsToSync = action.update.filter(c => {
                state.calls.forEach(sc => {
                    if (sc.callid === c.callid) {
                        //we already know about this call
                        return false;
                    }
                });
                return true;
            });


            state.calls = [...state.calls, ...callsToSync];

            break;
        case UPDATE_QUEUE_LOG:
            //to allow for debug
            state.debugLog = [...state.debugLog, ...action.update];
            if (state.debugLog.length > 100) {
                state.debugLog.shift();
            }
            //to avoid a leak shift the queue log.
            state.queueLog.shift();

            state.queueLog = [...state.queueLog, ...action.update];

            let newCalls = [];
            let callsToDelete = [];

            for (let e of action.update) {

                if (e.event === "ENTERQUEUE") {
                    if (!state.calls.some(c => c.callid === e.callid)) {
                        newCalls.push(e);
                    }
                }
                if (e.event === "ABANDON" || e.event === "CONNECT" || e.event === "COMPLETEAGENT" || e.event === "COMPLETECALLER") {
                    callsToDelete.push(e.callid)
                }
            }

            //update calls
            let callUpdate = state.calls.filter(c => !callsToDelete.includes(c.callid));
            state.calls = [...callUpdate, ...newCalls];

            break;
        default:
            break;
    }

    return state
}
