import React, { useState, useEffect, useRef } from "react";
import HPBXService from "../services/HPBXService";
import { Chat, Divider, TextArea, Button } from '@fluentui/react-northstar';
import { SendIcon } from '@fluentui/react-icons-northstar';
import { useDispatch, useSelector } from 'react-redux';

import { updateSMSMessages } from "../actions";
import { CreateContact } from "./CreateContact";

import format from 'date-fns/format'

export const Messaging = () => {

    const dispatch = useDispatch();
    const [remoteName, setRemoteName] = useState("");
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState("");
    const [error, setError] = useState("");

    const lastChat = useRef(null);

    const smsMessageConversations = useSelector(state => state.hpbx.smsMessageConversations);
    const contact = useSelector(state => state.hpbx.sms.selectedContact);

    const [createContactOpen, setCreateContactOpen] = useState(false);

    const profile = useSelector(state => state.user.userProfile);

    const [chatItems, setChatItems] = useState([{
        children: <Divider content="30 days ago" color="brand" important />,
        key: 'message-id-div-1',
    }]);


    useEffect(() => {
        generateItems();

        //if contact is set and you are getting new messages etc
        //you have read them as it is selected.
        //so update read 
        if (contact && contact.local && contact.remote) {

            console.log("UPDATE READ TS")
            let conversation = smsMessageConversations.find(c => c.local == contact.local && c.remote == contact.remote);

            if (conversation) {

                let nowTS = Math.ceil((new Date()).getTime() / 1000);

                HPBXService.updateReadTextMessages(conversation.server, conversation.local, conversation.remote, nowTS)
                    .then(res => {

                    })
                    .catch(err => {
                        console.error("GOT BACK AN ERROR on setting history:", err);
                    });
            }
        }

    }, [contact, smsMessageConversations]);


    useEffect(() => {
        if (lastChat.current && !isScrolledIntoView(lastChat.current)) {
            lastChat.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatItems]);

    function isScrolledIntoView(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;

        // Only completely visible elements return true:
        var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return isVisible;
    }


    function validatePhone(p) {

        p = p.replace(/[^\d]/g, "");

        return p;

        //setDest(p);
    }

    async function sendSMS() {

        setResponse("Sending...");
        setError("");

        let dest = validatePhone(contact.remote);
        if (dest.length < 9) {
            //probably not a valid dest
            setError("Sorry we can't send to this destination, it doesn't look like a valid phone number")
            return;
        }
        if (message.length < 2) {
            setError("Please have at least 2 characters");
            return;
        }

        let server = "";
        if (profile.hpbx.length) {

            for (let id of profile.hpbx) {

                if (id.numbers.includes(contact.local)) {
                    server = id.server;
                }
            }
        }

        if (server) {
            HPBXService.sendSMS(server, contact.local, dest, message)
                .then(res => {
                    setResponse("Your SMS has been sent")
                    setMessage("");
                    getSMSMessages();
                })
                .catch(err => {
                    console.log("GOT BACK AN ERROR:", err)
                    setError("Unable to send SMS")
                });
        }
    }


    async function getSMSMessages() {

        HPBXService.getTextMessageConversations()
            .then(res => {
                //console.log("SMS GOT BACK ::", res);
                //console.log(res.data.data)
                //setSMSMessages(res.data.data);
                dispatch(updateSMSMessages(res.data.data))
            })
            .catch(err => {
                console.log("SMS GOT BACK AN ERROR:", err)
            });


    }



    const generateItems = () => {
        let items = [{
            children: <Divider content="30 days ago" color="brand" important />,
            key: 'message-id-div-1',
        }];
        if (contact && contact.local && contact.remote) {
            let conversation = smsMessageConversations.find(c => c.local == contact.local && c.remote == contact.remote);


            if (conversation && conversation?.messages?.length) {
                console.log("remoteName", conversation?.remoteName)
                setRemoteName(conversation?.remoteName || "");
                for (let message of conversation.messages) {

                    let position = "end";
                    let author = "Me";

                    if (message.stype == "external") {
                        position = "start";
                        author = message.snumber;
                    }

                    let ts = format(new Date(message.time * 1000), "eee, MMM do, hh:mma ")


                    items.push({
                        message: (
                            <Chat.Message content={message.message} author={author} timestamp={ts} mine />
                        ),
                        contentPosition: position,
                        attached: 'top',
                        key: message.uniqueid,
                    });
                }
            }
        }

        setChatItems(items);
    }





    return (
        <>
            <CreateContact open={createContactOpen} setOpen={setCreateContactOpen} mobile_number={contact.remote}></CreateContact>
            {
                contact && contact.local && contact.remote ?
                    <div className="d-flex flex-column bd-highlight full-height">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                                <h1>{remoteName || contact.remote}</h1>
                                {remoteName == contact.remote && <Button content="Add Contact" primary onClick={() => { setCreateContactOpen(true) }} />}
                            </div>
                            <div className="me-5"><h1>{contact.local}</h1></div>
                        </div>
                        <div className="flex-grow-1 overflow-auto mb-3" >
                            <Chat items={chatItems} />
                            <div ref={lastChat}></div>
                        </div>
                        {error && <div className='alert alert-danger'>{error}</div>}
                        {response && <div className='alert alert-success'>{response}</div>}
                        <div class="m-3">
                            <div className="d-flex flex-row">
                                <TextArea fluid placeholder="Type a SMS message here..." styles={({ theme: { siteVariables } }) => ({
                                    backgroundColor: siteVariables.colorScheme.default.background,
                                    padding: '0.5em',
                                })} value={message} onChange={(e) => setMessage(e.target.value)} />
                                <Button icon={<SendIcon />} text iconOnly title="Send" onClick={sendSMS} disabled={message.length < 2} />
                            </div>
                        </div>
                    </div >
                    :
                    <div className="d-flex flex-column bd-highlight full-height align-items-center justify-content-center" >
                        <h1>Please start by selecting a person to chat with or creating a new mobile contact</h1>
                    </div>
            }
        </>

    );
}