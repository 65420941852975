import { UPDATE_PARKED } from '../actions';


const initialState = {
    //parkedCalls: []
}

export default function parkedReducer(state, action) {
    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case UPDATE_PARKED:

            if (!state[action.update.server]) {
                state[action.update.server] = [];
            }

            state[action.update.server] = [...action.update.parked_calls];

            state = JSON.parse(JSON.stringify(state));

            break;
        default:
            break;
    }

    return state
}