import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Button, Flex, FlexItem, Segment, Table, Dropdown, Popup, Loader, Text, Dialog } from "@fluentui/react-northstar";
import { RetryIcon, CallIcon } from '@fluentui/react-icons-northstar'
import HPBXService from "../services/HPBXService";
import format from 'date-fns/format'

import { ParkedCallRow } from "./ParkedCallRow";

export const ParkedCalls = () => {

    const profile = useSelector(state => state.user.userProfile);
    const parkedCalls = useSelector(state => state.parked);

    //helper for determining if we should render the calls table
    let hasCalls = false
    for (let server in parkedCalls) {
        if (parkedCalls[server].length) {
            hasCalls = true;
        }
    }

    const header = {
        items: ['Parking Slot', 'Time', 'Number', 'Pickup'],
    }
    let rows = buildRows();

    //mount / unmount
    useEffect(() => {

        rows = buildRows();

    }, [parkedCalls]);

    const makeCall = async (server, cnumber) => {

        HPBXService.makeCall(server, cnumber, "ppickup")
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)

                /*  if (err.response && err.response.status) {
                     if (err.response.status == 409) {
                         setPopupContent(
                             <Flex gap="gap.small">
                                 <Flex column>
                                     <Text content="The call is currently being presented to agents to be answered, and can not be picked up at this time" />
                                 </Flex>
                             </Flex>
                         )
                         setOpen(true);
                     }
                     if (err.response.status == 404) {
                         setPopupContent(
                             <Flex gap="gap.small">
                                 <Flex column>
                                     <Text content="The call has already been answered or is no longer available" />
                                 </Flex>
                             </Flex>);
                         setOpen(true);
                     }
                 } */
            });
    }


    function buildRows() {

        let rows = [];

        for (let server in parkedCalls) {

            rows.push(parkedCalls[server].map(c => {
                return {
                    Key: c.callid,
                    items: [
                        c.park,
                        format(new Date(c.start * 1000), "hh:mma "),
                        (c.first_dtype === 'external' ? c.first_dnumber : c.first_snumber),
                        <button type="button" class="btn btn-outline-secondary" onClick={() => makeCall(server, c.park)}> {<CallIcon />}</button >
                    ]
                };
            }))
        }

        return rows.flat();
    }

    function buildMobileRows() {
        let rows = [];

        for (let server in parkedCalls) {

            rows.push(parkedCalls[server].map(c => {
                return <ParkedCallRow call={c} makeCall={makeCall} server={server}></ParkedCallRow>
            }))
        }

        return rows;
    }

    return (
        <>
            {hasCalls ?
                <>
                    <div className="d-none d-md-inline ">
                        <Table
                            header={header}
                            rows={rows}
                            aria-label="Parked Calls"
                        /> </div>
                    <div className="d-inline d-md-none ">
                        {
                            buildMobileRows()
                        }
                    </div>
                </> :
                <Segment className="text-center">There are currently no parked calls</Segment>
            }



        </>
    );
}
