import { UPDATE_UNANSWERED_CALLS } from '../actions';


const initialState = {
    //unansweredCalls: []
}

export default function unansweredCallsReducer(state, action) {
    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case UPDATE_UNANSWERED_CALLS:

            if (!state[action.update.server]) {
                state[action.update.server] = [];
            }

            state[action.update.server] = [...action.update.unanswered_calls];

            state = JSON.parse(JSON.stringify(state));

            break;
        default:
            break;
    }

    return state
}