import React, { useState, useEffect } from "react";
import { Input, Flex, Dropdown, Button, Dialog, useFluentContext, TextArea } from "@fluentui/react-northstar";
import { updateSelectedSMSContact } from "../actions";
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon, CloseIcon } from '@fluentui/react-icons-northstar';
import HPBXService from "../services/HPBXService";
import { useHistory } from "react-router-dom";


export const CreateSMSBroadcast = ({ open, setOpen, destination }) => {

    const dispatch = useDispatch();
    const theme = useFluentContext().theme;
    const history = useHistory();

    const [src, setSrc] = useState("");
    const [srcList, setSrcList] = useState([]);

    const contacts = useSelector(state => state.hpbx.contacts);

    const [selectedContacts, setSelectedContacts] = useState([]);

    const [searchResults, setSearchResults] = useState([]);

    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");

    const profile = useSelector(state => state.user.userProfile);

    const [response, setResponse] = useState("");
    const [error, setError] = useState("");

    const [smsBroadcastGroups, setSMSBroadcastGroups] = useState([]);
    const [smsBroadcastGroupsList, setSMSBroadcastGroupsList] = useState([]);

    useEffect(() => {
        HPBXService.getSMSBroadcastDestinationGroups()
            .then(res => {
                console.log("SMS GROUPS:", res.data)

                //{ server: dg.server, contacts: JSON.parse(dg.contacts) }

                let names = [];
                let bcgroups = [];
                res.data.data.forEach(sg => {
                    bcgroups.push(...sg.destination_groups.map(dg => ({ ...dg, contacts: JSON.parse(dg.contacts) })));
                    names.push(...sg.destination_groups.map(dg => dg.group_name));
                });

                setSMSBroadcastGroups(bcgroups);

                setSMSBroadcastGroupsList(names);
            })
            .catch(err => console.error(err))
    }, []);



    useEffect(() => {
        console.log("Destination is::", destination)
        if (destination) {
            setNumber(destination)
        }
    }, [destination]);

    useEffect(() => {
        // console.log("Get Src numbers");
        getSrcList();
    }, []);

    async function getSrcList() {

        let srcs = [];


        if (profile.hpbx.length) {

            for (let id of profile.hpbx) {
                srcs.push(...id.numbers.filter(n => n.length > 9 && n.startsWith("04")));
            }
        }
        setSrcList(srcs);
    }


    async function sendSMSBroadcast() {

        setResponse("Sending...");
        setError("");

        /* let dest = validatePhone(contact.remote);
        if (dest.length < 9) {
            //probably not a valid dest
            setError("Sorry we can't send to this destination, it doesn't look like a valid phone number")
            return;
        } */
        if (message.length < 2) {
            setError("Please have at least 2 characters");
            return;
        }

        let server = "";
        if (profile.hpbx.length) {

            for (let id of profile.hpbx) {

                if (id.numbers.includes(src)) {
                    server = id.server;
                }
            }
        }

        if (server) {

            let destinations = selectedContacts.map(c => c.mobile_number);

            HPBXService.sendSMSBroadcast(server, src, destinations, message)
                .then(res => {
                    setResponse("Your SMS Broadcast has been sent")
                    setMessage("");
                })
                .catch(err => {
                    console.log("GOT BACK AN ERROR:", err)
                    setError("Unable to send SMS")
                });
        }
    }


    const searchContacts = (name) => {

        if (name) {

            for (let serverContactList of contacts) {

                let contactList = serverContactList.contacts;
                let server = serverContactList.server;
                let results = [];
                for (let g in contactList) {
                    if (contactList[g].length) {

                        results.push(...contactList[g].filter(c => (c.display_name.toLowerCase().includes(name.toLowerCase()) && c.mobile_number)));
                    }
                }

                results = results.slice(0, 10);

                setSearchResults(results);
            }
        }
        else {
            setSearchResults([]);
        }
    }

    const addContact = (c) => {

        setSelectedContacts(old => {

            if (!old.find(o => o.mobile_number === c.mobile_number)) {

                return [...old, c];
            }

            return old;

        });
    }

    const removeContact = (c) => {

        setSelectedContacts(old => old.filter(o => o.mobile_number !== c.mobile_number));
    }

    const addGroup = (g) => {
        //console.log("ADD GROUP::", g);

        let group = smsBroadcastGroups.find(gr => gr.group_name === g);

        if (group) {

            setSelectedContacts(old => {
                return [...old, ...group.contacts];
            });
        }


    }

    const closePopup = () => {
        createContact();
        setOpen(false)
    }

    //this will actually not create anything but sets contact to a contact that you can talk to like how talking to someone with an empty message list would be.
    const createContact = () => {
        dispatch(updateSelectedSMSContact({ local: src, remote: number }));

        //if page is not already messaging then navigate there.
        if (history.location !== "/messaging") {
            history.push("/messaging");
        }

    }

    function validatePhone(p) {
        p = p.replace(/[^\d]/g, "");
        setNumber(p);
    }


    return (
        <>
            <Dialog

                open={open}
                onOpenChange={(e, { open }) => setOpen(open)}
                confirmButton={{ content: "Send", disabled: (src.length < 9 || selectedContacts.length < 1 || message.length < 2) ? true : false }}
                cancelButton="Close"
                onConfirm={sendSMSBroadcast}
                onCancel={() => { setOpen(false) }}
                styles={{ "minWidth": "410px" }}
                content={{
                    content:
                        <div className="d-flex flex-column gap-3">
                            <div>
                                <Dropdown
                                    items={srcList}
                                    placeholder="Select your Source Number"
                                    onChange={(e, selected) => setSrc(selected.value)}
                                    inverted
                                />
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    Destinations
                                </div>
                                <div className="card-body d-flex flex-row gap-1">
                                    {selectedContacts.map(sc =>
                                        <Button icon={<CloseIcon />} iconPosition="after" content={`${sc.display_name}`} secondary onClick={() => { removeContact(sc) }} />
                                    )}
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body d-flex flex-column gap-3">
                                    <div>
                                        <Input clearable labelPosition="inline" label="Search for a Contact"
                                            onChange={(e) => searchContacts(e.target.value)} />
                                    </div>
                                    <div>

                                        <div className="card">
                                            <div className="card-header">
                                                Contact Search Results
                                            </div>

                                            <div className="row">
                                                {searchResults.map(r =>
                                                    <div class="list-group-item col-sx-12 col-md-6">

                                                        <Button content={`Add ${r.display_name} - ${r.mobile_number}`} primary onClick={() => { addContact(r) }} />
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <Dropdown
                                            items={smsBroadcastGroupsList}
                                            placeholder="Or add a destination group"
                                            onChange={(e, selected) => addGroup(selected.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <TextArea
                                        fluid
                                        placeholder="Type a SMS message here..."
                                        styles={({ theme: { siteVariables } }) => ({
                                            backgroundColor: siteVariables.colorScheme.default.background,
                                            padding: '0.5em',
                                        })} value={message} onChange={(e) => setMessage(e.target.value)} />
                                </div>

                                {error && <div className='alert alert-danger'>{error}</div>}
                                {response && <div className='alert alert-success'>{response}</div>}
                            </div>

                        </div>,
                    'aria-label': 'Send a SMS to multiple people',
                }}
                trapFocus
            />
        </>
    )
}
