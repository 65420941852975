import React, { useState } from "react";
import { Card, Text, Button } from "@fluentui/react-northstar";
import { PlayIcon } from '@fluentui/react-icons-northstar';
import HPBXService from "../services/HPBXService";
import format from 'date-fns/format'




const VMCard = (mail) => {
    const [message, setMessage] = useState("");

    async function getMessage(msg) {

        console.log("DOWNLOAD AND PLAY MESSAGE:::", msg)
        HPBXService.getHPBXMessage(msg)
            .then(res => {
                let fileInfo = res.data.data;
                console.log(fileInfo)
                setMessage(URL.createObjectURL(new Blob([base64ToArrayBuffer(fileInfo.data)], { type: fileInfo.mimetype })))
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)
            });
    }

    function base64ToArrayBuffer(base64) {
        var binary_string = atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    return (mail && <Card
        aria-roledescription="card avatar"
        elevated
        inverted
        fluid
        styles={{ height: "max-content", margin: "0.5em 0" }}
    >
        <Card.Header styles={{ "margin-bottom": "0" }}>

            <div className="row">
                <div className="col-12 col-lg-2">
                    <Text weight="bold">{mail.callerid}</Text>
                </div>
                <div className="col-12 col-lg-2">
                    <Text weight="small">Time {format(new Date(mail.time * 1000), "hh:mma dd/MM/yyyy")}</Text>
                </div>
                <div className="col-12 col-lg-2">
                    <Text size="small">Duration: {mail.duration}</Text>
                </div>
                <div className="col-12 col-lg-6">
                    {!message && <Button fluid onClick={() => getMessage(mail.name)} ><PlayIcon /></Button>}
                    {message &&
                        <audio id={mail.name} controls="controls">
                            <source src={message} />
                        </audio>}
                </div>
            </div>
        </Card.Header>
    </Card >);
};

export default VMCard;
