import { UPDATE_SHOW_QUEUES, UPDATE_SHOW_DEBUG, UPDATE_SHOW_UNREGISTERED } from '../actions';

const initialState = {
    showAllQueues: "false",
    showUnregistered: "false",
    showDebug: "false"
}


export default function settingsReducer(state, action) {

    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case UPDATE_SHOW_UNREGISTERED:
            state.showUnregistered = action.payload;
            break;
        case UPDATE_SHOW_QUEUES:
            state.showAllQueues = action.payload;
            break;
        case UPDATE_SHOW_DEBUG:
            state.showDebug = action.payload;
            break;
        default:
            break;
    }

    return state
}