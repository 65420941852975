import React from "react";
import { Avatar, Flex, Skeleton, Text } from "@fluentui/react-northstar";
import { StarIcon } from '@fluentui/react-icons-northstar'
import { useDispatch } from 'react-redux';

import { updateProfileFavorite } from "../actions";

export const ProfileRailItem = ({ loading, profile }) => {

    //console.log(profile);

    const dispatch = useDispatch();

    let avatar = {
        image: profile.photoUrl,
        status: {
            state: "unknown"
        }
    };

    if (profile.presence) {
        switch (profile.presence.availability) {
            case "Offline":
                avatar.status.state = 'unknown';
                break;
            case "Available":
                avatar.status.state = 'success';
                break;
            case "Away":
                avatar.status.state = 'warning';
                break;
            case "Busy":
            case "DoNotDisturb":
                avatar.status.state = 'error';
                break;

        }
    }

    function toggleFavourite() {
        profile.isFav = !profile.isFav;

        dispatch(updateProfileFavorite(profile))
    }

    let Title = "";

    if (profile && profile.hpbx && profile.hpbx.length) {
        profile.hpbx.forEach
            (id => {
                if (id && id.telephoneLines) {
                    Title += "Ext: " + id.telephoneLines[0] + " Svr:" + id.server + "\n";
                }
            })
    }

    return (
        <div className="row p-2 rail-item" title={Title}>
            {profile && (
                <Flex gap="gap.medium">
                    <Avatar size="small" {...avatar} />
                    <Flex column>
                        <Text content={profile.displayName} size="small" />
                        {
                            /* profile.hpbx && profile.hpbx.map(id =>
                                <Text content={id && id.telephoneLines && "Ext: " + id.telephoneLines[0] + " " + id.server} size="smaller" />
                            ) */
                        }
                    </Flex>
                    <Flex.Item push>
                        {profile.isFav ?
                            <button type="button" className="btn" style={{ color: "gold" }} onClick={toggleFavourite}>{<StarIcon />}</button>
                            :
                            <button type="button" className="btn" style={{ color: "grey" }} onClick={toggleFavourite}>{<StarIcon outline />}</button>
                        }

                    </Flex.Item>
                </Flex>
            )}
        </div>
    );
}
