import React, { useState, useEffect, useContext, useCallback } from "react";
import { Segment, Accordion } from "@fluentui/react-northstar";
import { QueueCard } from "./QueueCard";
import { useSelector, useDispatch } from 'react-redux';

import { useTeamsFx } from "./lib/useTeamsFx";

import format from 'date-fns/format'
import { CallHistory } from "./CallHistory";
import { ParkedCalls } from "./ParkedCalls";
import { Callbacks } from "./Callbacks";
import { ActiveCalls } from "./ActiveCalls";
import { UnansweredCalls } from "./UnansweredCalls";
import { StatTiles } from "./StatTiles";


const Main = () => {

  const showDebugLog = useSelector(state => state.settings.showDebug);

  const debugLog = useSelector(state => state.queue.debugLog);

  const queues = useSelector(state => state.hpbx.queues);

  const unanswered_calls = useSelector(state => state.unansweredCalls);

  const parkedCalls = useSelector(state => state.parked);

  //const profile = useSelector(state => state.user.userProfile);

  const { theme, loading } = useTeamsFx();
  //console.log("THEME", theme.siteVariables.colorScheme.brand)

  let colorScheme = theme.siteVariables.colorScheme.brand;

  let boxStyle = {
    'border': `0.1em solid ${colorScheme.border}`,
  }

  let unansweredCallCount = 0;
  let parkedCallCount = 0;
  //helper for determining if we should render the calls table
  for (let server in unanswered_calls) {
    unansweredCallCount += unanswered_calls[server].length;
  }

  for (let server in parkedCalls) {
    parkedCallCount += parkedCalls[server].length;
  }


  const genActiveCallsAccordion = () => {

    const activeCallsPanel = <ActiveCalls></ActiveCalls>;
    const callsWaitingPanel = <UnansweredCalls calls={unanswered_calls}></UnansweredCalls>;
    const parkedCallsPanel = <ParkedCalls></ParkedCalls>;
    const callbacksPanel = <Callbacks></Callbacks>;
    const callHistoryPanel = <CallHistory></CallHistory>;


    const panels = [
      {
        key: 'activeCallsP',
        title: 'Active Calls',
        content: activeCallsPanel,
      },
      {
        key: 'callWaitP',
        title: 'Calls Waiting',
        content: callsWaitingPanel,
      },
      {
        key: 'parkedCallsP',
        title: 'Parked Calls',
        content: parkedCallsPanel,
      },
      {
        key: 'callbacks',
        title: 'Callbacks',
        content: callbacksPanel,
      },
      {
        key: 'callHistoryPanel',
        title: 'Recent Calls',
        content: callHistoryPanel,
      },
    ];

    return <Accordion defaultActiveIndex={[0, 1, 2, 3]} panels={panels} />
  }

  let callPanel = genActiveCallsAccordion();
  let avg_wait_time_over_current_callers = 0;


  if (unansweredCallCount > 0) {

    let totalWait = 0;

    for (let server in unanswered_calls) {
      totalWait += unanswered_calls[server].reduce((p, c) => {
        p += Math.floor((new Date().getTime() - (c.start * 1000)) / 1000);
        return p;
      }, 0);
    }

    avg_wait_time_over_current_callers = Math.floor(totalWait / unansweredCallCount);
  }


  return (
    <div className="g-0" style={{ padding: "2em 1em" }}>
      <StatTiles callers_waiting={unansweredCallCount} avg_wait_time={avg_wait_time_over_current_callers} parked_calls={parkedCallCount}></StatTiles>
      {callPanel}

      {
        showDebugLog === 'true' ?
          <Segment>
            <h1>DEBUG LOG:</h1>
            {
              debugLog && debugLog.map(dl => <li>[{format(new Date(dl.time * 1000), "hh:mma ")}] {JSON.stringify(dl)}</li>)
            }
          </Segment>
          :
          <></>
      }

      <div className="d-inline d-md-none ">
        {queues.map(q => {
          return <QueueCard queue={q} ></QueueCard>
        })}
      </div>

    </div>


  );
}

export default Main;