import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Text, Card, Flex, Segment, Status, Dialog } from "@fluentui/react-northstar";
import { ContactCardIcon, CallIcon, PhoneClockIcon } from '@fluentui/react-icons-northstar'

import util from "./lib/util";

export const ParkedCallRow = ({ server, call, makeCall }) => {

    let waitTime = Math.floor((new Date().getTime() - (call.start * 1000)) / 1000);
    let waitTimeDisplay = util.formatSeconds(waitTime);

    return (
        <>
            <div className="row m-3 d-block d-md-none">
                {call && (

                    <Segment>
                        <Flex gap="gap.medium" space="around">

                            <h4><CallIcon />{call.callerid_external}</h4>

                            <h4><i class="bi bi-clock"></i>{waitTimeDisplay}</h4>
                        </Flex>
                        {makeCall &&
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-success p-3 m-3" onClick={() => makeCall(server, call.park)}>{<CallIcon />}</button>
                            </div>}
                    </Segment>


                )}
            </div>
        </>
    );
}
