import React from "react";
import ReactDOM from "react-dom";
import InitFx from "./components/InitFx";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.min.js";
import store from './store';
import { Provider } from 'react-redux'

ReactDOM.render(
    <Provider store={store}>
        <InitFx />
    </Provider>,
    document.getElementById("root"));
