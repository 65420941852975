import React, { useState, useEffect } from "react";
import { Avatar, Flex, Table, Text, Dialog, Status, Segment, gridRowNestedBehavior } from "@fluentui/react-northstar";
import { CallIcon } from '@fluentui/react-icons-northstar';
import HPBXService from "../services/HPBXService";
import format from 'date-fns/format'

import { CallRow } from "./CallRow";

export const UnansweredCalls = ({ calls }) => {

    const [timer, setTimer] = useState(0);

    const [open, setOpen] = useState(false);
    const [popupContent, setPopupContent] = useState(<></>);


    const closePopup = () => {
        setOpen(false)
    }

    //cheat to redraw each second
    useEffect(() => {
        let interval = setTimeout(() => {
            setTimer(t => t + 1);
        }, 1000);

        return () => clearTimeout(interval);
    }, [timer]);


    //helper for determining if we should render the calls table
    let hasCalls = false
    for (let server in calls) {
        if (calls[server].length) {
            hasCalls = true;
        }
    }


    function getState(waitTime) {
        let state = "success";

        if (waitTime >= 60) {
            state = "warning";
        }

        if (waitTime >= 90) {
            state = "error";
        }
        return state;
    }

    const callPickUp = async (server, call) => {

        HPBXService.callPickUp(server, call.callid, call.queue, call.callerid)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)

                if (err.response && err.response.status) {
                    if (err.response.status == 409) {
                        setPopupContent(
                            <Flex gap="gap.small">
                                <Flex column>
                                    <Text content="The call is currently being presented to agents to be answered, and can not be picked up at this time" />
                                </Flex>
                            </Flex>
                        )
                        setOpen(true);
                    }
                    if (err.response.status == 404) {
                        setPopupContent(
                            <Flex gap="gap.small">
                                <Flex column>
                                    <Text content="The call has already been answered or is no longer available" />
                                </Flex>
                            </Flex>);
                        setOpen(true);
                    }
                }
            });
    }

    const header = {
        items: [{ content: '', className: "single-character-column" }, '', 'Caller', 'Wait Time', ''],
    }



    function generateRows() {

        let rows = [];

        for (let server in calls) {

            rows.push(calls[server].map(c => {
                let waitTime = Math.floor((new Date().getTime() - (c.start * 1000)) / 1000);
                if (waitTime < 0) waitTime = 0;

                return {
                    Key: c.callid,
                    items: [
                        {
                            content: <Status state={getState(waitTime)} />,
                            className: "single-character-column"
                        },
                        c.queue_name || c.huntgroup_name,
                        c.callerid_external,
                        waitTime,
                        (c.dtype === 'queue' ? <button type="button" class="btn btn-success" onClick={() => callPickUp(server, c)}>{<CallIcon />}</button> : null)
                    ]
                }
            }))
        }

        //console.log("generateRows", rows)

        return rows.flat();

    }

    let rows = generateRows();

    function generateMobileRows() {

        let rows = [];

        for (let server in calls) {

            rows.push(calls[server].map(c => {
                if (c.dtype === 'queue') {
                    return <CallRow call={c} callPickUp={callPickUp} server={server}></CallRow>
                }
                else {
                    return <CallRow call={c} server={server}></CallRow>
                }
            }))
        }

        return rows;

    }

    let mobileRows = generateMobileRows();

    return (
        <>
            <Dialog
                open={open}
                onOpenChange={(e, { open }) => setOpen(open)}
                confirmButton="OK"
                onConfirm={closePopup}
                content={{
                    content: popupContent,
                    'aria-label': 'Call Pickup Result',
                }}
                trapFocus
            />

            {hasCalls ?
                <>
                    <div className="d-none d-md-inline ">
                        <Table
                            header={header}
                            rows={rows}
                            aria-label="Calls Waiting"
                        /></div>
                    <div className="d-inline d-md-none ">
                        {mobileRows}
                    </div>
                </> :
                <Segment className="text-center">There are currently no calls waiting</Segment>
            }

        </>
    );
}
