import React from "react";
import { useSelector } from 'react-redux';
import { Segment, Flex } from "@fluentui/react-northstar";
import { EditIcon, CalendarIcon, InfoIcon, CallIcon } from '@fluentui/react-icons-northstar'
import format from 'date-fns/format'

export const CallHistoryRow = ({ call, openDialog }) => {

    const queues = useSelector(state => state.hpbx.queues);


    let queueName = getQueueName() || "N/A";

    function getQueueName() {
        let q = queues.find(q => q.id == call.queue);
        if (q) {
            return q.name;
        }
    }
    return (
        <>
            <div className="row m-3 d-block d-md-none">
                {call && (
                    <Segment>
                        <Flex gap="gap.medium" space="around">

                            <h4><CalendarIcon />{format(new Date(call.time * 1000), "hh:mma ")}</h4>

                            <h4><CallIcon />{call.callerid}</h4>

                            <h4><InfoIcon />{"Outcome: " + (call.outcome?.description || "")}</h4>
                        </Flex>

                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-outline-secondary" onClick={() => openDialog(call)} > {< EditIcon />}</button >
                        </div>
                    </Segment>
                )}
            </div>
        </>
    );
}
