import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Button, Flex, FlexItem, Segment, Table, Dropdown, Popup, Loader, Avatar, Dialog } from "@fluentui/react-northstar";
import { RetryIcon, CallIcon, CallParkingIcon, PhoneArrowIcon, CallEndIcon } from '@fluentui/react-icons-northstar'
import HPBXService from "../services/HPBXService";
import format from 'date-fns/format'
import { AnsweredCallRow } from './AnsweredCallRow';
import util from "./lib/util";

export const ActiveCalls = () => {


    const profiles = useSelector(state => state.users.users);
    const hpbxUsers = useSelector(state => state.users.hpbxOnlyUsers);
    const [loading, setLoading] = useState(false);

    const profile = useSelector(state => state.user.userProfile);
    const activeCalls = useSelector(state => state.activeCalls);

    const [timer, setTimer] = useState(0);


    //cheat to redraw each second
    useEffect(() => {
        let interval = setTimeout(() => {
            setTimer(t => t + 1);
        }, 1000);

        return () => clearTimeout(interval);
    }, [timer]);

    //helper for determining if we should render the calls table
    let hasCalls = false
    for (let server in activeCalls) {
        if (activeCalls[server].length) {
            hasCalls = true;
        }
    }


    const [open, setOpen] = useState(false);
    const [dialog, setDialog] = useState(<Dialog></Dialog>);

    const [selectedCall, setSelectedCall] = useState({});

    const [xferDest, setXferDest] = useState("");


    function openDialog(server, call) {
        setSelectedCall({ ...call, server });
        setOpen(true);
    }

    useEffect(() => {

        if (selectedCall && Object.keys(selectedCall).length !== 0) {
            let d = (<Dialog
                open={open}
                content={{
                    content: popupContent(selectedCall),
                    'aria-label': 'Outcome',
                }}
                trapFocus
            />);
            setDialog(d);
        }
        else {
            setDialog(<></>);
        }
    }, [open, selectedCall, xferDest])

    const popupContent = (call) => {
        //build dropdown

        //let inputItems = [];

        let inputItems = ([...profiles, ...hpbxUsers].reduce(
            (p, c) => {
                let id = c.hpbx.find(h => h.server == call.server);

                if (id) {
                    let item = {
                        header: `${c.displayName} (${c.presence?.availability})`,
                        image: c.photoUrl || null,
                        content: id.telephoneLines[0]
                    }
                    p.push(item);
                }

                return p;
            },
            []
        ));


        //inputItems.push(...profiles.map(p => ({ header: p.displayName, image: p.photoUrl, content: p.hpbx.telephoneLines[0] })));
        //inputItems.push(...hpbxUsers.map(p => ({ header: p.displayName, image: p.photoUrl, content: p.hpbx.telephoneLines[0] })));

        return (loading) ?
            (
                <Loader style={{ margin: 100 }} />
            )
            :
            (
                <>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <Dropdown
                                search
                                items={inputItems}
                                placeholder="Start typing a name or enter the number"
                                noResultsMessage=""
                                a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                                onChange={(event, option, index) => {
                                    //console.log("onCHANGE:::", option);
                                    if (option && option.value) {
                                        setXferDest(option.value.content)
                                    }
                                }}
                                onSearchQueryChange={(event, data) => {
                                    //console.log("onSearchQueryChange:::", event, data);
                                    setXferDest(data.searchQuery);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <Button secondary content="Cancel" onClick={() => closePopup()} />
                        {
                            <Button primary content="Transfer" disabled={xferDest ? false : true} onClick={() => transferCall(call.server, call)} />
                        }
                    </div>

                </>
            );
    }

    const closePopup = () => {
        setOpen(false)
        setDialog(<Dialog></Dialog>);
    }



    const header = {
        items: ['Talk Time', 'Number', 'Options'],
    }
    let rows = buildRows();

    useEffect(() => {
        rows = buildRows();

        if (open) {
            //console.log("checking if xfer still available");
            //do we still have the active call - needs potentially to have the server also checked.
            let stillActive = false;
            for (let server in activeCalls) {
                if (activeCalls[server].find(c => c.uniqueid == selectedCall.uniqueid)) {
                    stillActive = true;
                }
            }

            if (!stillActive) {
                closePopup();
            }
        }

    }, [activeCalls]);

    const parkCall = async (server, c) => {

        //channel	Integer	1 to redirect the calling channel, 2 to redirect the called channel.
        let channel = 1;
        if (c.dtype === "external" || c.dtype === "forward" || c.dtype.includes("pickup")
            || profile?.hpbx?.telephoneLines?.includes(c.snumber)) {
            channel = 2;
        }


        HPBXService.callTransfer(server, c.uniqueid, "*7", channel)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)

            });
    }


    const transferCall = async (server, c) => {

        let dest = xferDest;

        //console.log("XFER_DEST:::", dest);
        //channel	Integer	1 to redirect the calling channel, 2 to redirect the called channel.
        let channel = 1;
        if (c.dtype === "external" || c.dtype.includes("pickup")) {
            channel = 2;
        }
        //console.log(server, c.uniqueid, dest, channel)

        //transform xfer dest
        dest = dest.replace(/[^\d\+]/g, "");
        dest = dest.replace(/0(\d{9})/g, "+61$1");

        HPBXService.callTransfer(server, c.uniqueid, dest, channel)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)

            });
    }


    const hangupCall = async (server, c) => {

        HPBXService.hangupCall(server, c.uniqueid)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)

            });
    }

    function buildRows() {


        let rows = [];

        for (let server in activeCalls) {

            rows.push(activeCalls[server].map(c => {

                let num = '';

                if (c.bridged_callid) {
                    if (c.bridged_dtype === 'external') {
                        num = c.bridged_dnumber;
                    }
                    else {
                        num = c.bridged_callerid_external;
                    }
                }
                else {
                    if (c.dtype === 'external') {
                        num = c.dnumber;
                    }
                    else {
                        num = c.callerid_external;
                    }
                }

                let talkTime = Math.floor((new Date().getTime() - (c.answered * 1000)) / 1000);

                let talkTimeDisplay = util.formatSeconds(talkTime);

                return {
                    Key: c.callid,
                    items: [
                        talkTimeDisplay,
                        num,
                        <Flex gap="gap.small" vAlign="center">
                            <button type="button" title="Park" className="btn btn-primary" onClick={() => parkCall(server, c)}><CallParkingIcon /></button >
                            {
                                <button type="button" title="Transfer" className="btn btn-info" onClick={() => openDialog(server, c)}><PhoneArrowIcon /><CallIcon /></button >
                            }
                            <button type="button" title="Hangup" className="btn btn-danger" onClick={() => hangupCall(server, c)}><CallEndIcon /></button >
                        </Flex>
                    ]
                };
            }))
        }
        return rows.flat();
    }

    function buildMobileRows() {
        let rows = [];

        for (let server in activeCalls) {
            rows.push(activeCalls[server].map(c => {
                return <AnsweredCallRow call={c} parkFn={parkCall} hangupFn={hangupCall} xferFn={openDialog} server={server}></AnsweredCallRow>
            }))
        }
        return rows;
    }

    return (
        <>
            {dialog}
            {hasCalls ?
                <>
                    <div className="d-none d-md-inline ">
                        <Table
                            header={header}
                            rows={rows}
                            aria-label="Active Calls"
                        />
                    </div>
                    <div className="d-inline d-md-none ">
                        {buildMobileRows()}
                    </div>
                </>
                :
                <Segment className="text-center">There are currently no active calls</Segment>

            }
        </>
    );
}
