function formatSeconds(time) {

    if (time < 0) {
        time = 0;
    }

    let formatted = "";
    if (time > 60) {
        let mins = Math.floor(time / 60);
        let seconds = Math.floor(time % 60);

        let minsTxt = mins > 1 ? "mins" : "min";
        let secTxt = seconds != 1 ? "secs" : "sec";

        formatted = `${mins} ${minsTxt} ${seconds} ${secTxt}`;
    }
    else {

        formatted += `${time} secs`;
    }

    return formatted;
}

export default { formatSeconds }