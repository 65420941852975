import { useData } from "./useData";
import { TeamsUserCredential, createMicrosoftGraphClient } from "@microsoft/teamsfx";

export function useGraph(asyncFunc, options) {
  const { scope } = {
    scope: [
      "User.Read",
      "User.ReadBasic.All",
      "Presence.Read.All",
      "openid"
    ], ...options
  };
  const initial = useData(async () => {

    try {
      const credential = new TeamsUserCredential();
      const graph = createMicrosoftGraphClient(credential, scope);
      return await asyncFunc(graph);
    } catch (err) {
      /*if (err.code.includes("UiRequiredError")) {
        // Silently fail for user didn't login error
      } else {*/
      throw err;
      //}
    }
  });

  //modified this a bit
  //the sp can take some time to copy to the tenant
  // because of this we have a loop retrying each 5 seconds.
  //
  const { data, error, loading, reload } = useData(
    async () => {
      try {
        console.log("credential")
        const credential = new TeamsUserCredential();
        console.log("login")
        await credential.login(scope);
      }
      catch (err) {
        console.log(err)
      }

      //retry loop - sometimes this needs a small window of time
      for (let i = 0; true; i++) {
        try {
          console.log("sleep for 5 seconds")
          await sleep(5000);
          console.log("DONE: sleep for 5 seconds")
          const credential = new TeamsUserCredential();
          // Important: tokens are stored in sessionStorage, read more here: https://aka.ms/teamsfx-session-storage-notice
          const graph = createMicrosoftGraphClient(credential, scope);
          return await asyncFunc(graph);
        }
        catch (err) {
          if (i > 2) {
            throw err;
          }
        }

      }
    },
    { auto: false }

  );

  return data || error || loading
    ? { data, error, loading, reload }
    : {
      data: initial.data,
      error: initial.error,
      loading: initial.loading,
      reload,
    };
}

async function sleep(ms) {
  return new Promise((resolve, reject) => { setTimeout(() => resolve(), ms) })
}
