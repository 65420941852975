import React, { useState, useEffect, useContext, useCallback } from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader, Alert } from "@fluentui/react-northstar";
import { HashRouter as Router, Route } from "react-router-dom";
import { useTeamsFx } from "./lib/useTeamsFx";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Main from "./Main";
import Voicemail from "./Voicemail"
import { Messaging } from "./Messaging"
import { useGraph } from "./lib/useGraph";
//import Nav from "./Nav";

import HPBXService from "../services/HPBXService";

import { updateUserProfile, updateUsers, updateHPBXQueues, updateHPBXOnlyUsers, updateGroups, updateContacts } from '../actions';

import { useSelector, useDispatch } from 'react-redux';

import { LeftRail } from "./LeftRail";

import "./App.css";

import PollerService from "../services/PollerService";


/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {


  const { theme, loading } = useTeamsFx();

  const [hpbxLoading, setHPBXLoading] = useState(true);

  const [hpbxPeople, setHPBXPeople] = useState([]);
  const [error, setError] = useState("");
  const [teamsProfiles, setTeamsProfiles] = useState([]);

  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.userProfile);

  const profiles = useSelector(state => state.users.users);


  //get your profile
  useGraph(
    async (graph) => {
      let me = await graph.api("/me").get();
      let photoUrl = "";
      try {
        let photo = await graph.api("/me/photo/$value").get();
        me.photoUrl = URL.createObjectURL(photo);
      } catch {
        // Could not fetch photo from user's profile, return empty string as placeholder.
      }
      await getHPBXLink(me);
      return { profile, photoUrl };
    }
  );

  //useGraph gets a token.
  ////get your team mates profiles
  useGraph(
    async (graph) => {
      const profilesObj = await graph.api("/users?$top=999").get();
      let tmpProfiles = profilesObj.value;

      var itemsProcessed = 0;
      let favorites = JSON.parse(localStorage.getItem("favorites"));



      tmpProfiles.forEach(p => {

        if (favorites && favorites.length && favorites.includes(p.id)) {
          p.isFav = true;
        }

        graph.api(`/users/${p.id}/photo/$value`).get()
          .then(photo => {
            p.photoUrl = URL.createObjectURL(photo);
            itemsProcessed++;
            if (itemsProcessed >= tmpProfiles.length) {
              setTeamsProfiles([...tmpProfiles]);
            }
          })
          .catch(err => {
            p.photoUrl = "";
            itemsProcessed++;
            if (itemsProcessed >= tmpProfiles.length) {
              setTeamsProfiles([...tmpProfiles]);
            }
          })

      });

      return;
    }
  );

  useEffect(() => {
    async function getHPBXQueues() {

      HPBXService.getHPBXQueues()
        .then(async (res) => {

          //get the agents for the queues as well
          let queues = res.data.data;
          //async / await so don't use foreach here
          for (let i = 0; i < queues.length; i++) {
            let agentRes = await HPBXService.getHPBXQueueAgents(queues[i].server, queues[i].id);
            let agents = agentRes.data.data;
            if (profile.hpbx.length && agents.length > 0) {

              queues[i].agents = agents;

              let hpbxProfile = profile.hpbx.find(id => id.server == queues[i].server);
              if (hpbxProfile) {
                let me = agents.find(a => a.dnumber === hpbxProfile.telephoneLines[0]);
                if (me) {
                  queues[i].loggedIn = 1;
                  queues[i].available = me.available;
                }
              }
            }
          }


          dispatch(updateHPBXQueues(queues));
          setHPBXLoading(false);
          //setHPBXMessage("Queues have been found");
        })
        .catch(err => {
          //console.log("GOT BACK AN ERROR:", err)
          //setHPBXMessage("Couldn't Load queues.")
        });
    }


    if (profile.hpbx && profile.hpbx.length) getHPBXQueues();

  }, [profile]);

  useEffect(() => {
    async function getHPBXPeople() {


      HPBXService.getHPBXPeople()
        .then(res => {
          //console.log("GOT BACK ::", res);
          setHPBXPeople(res.data);

          teamsProfiles.forEach(tp => {
            res.data.forEach(hp => {
              if (tp.userPrincipalName === hp.username) {
                //console.log("Found a match:: ", tp.userPrincipalName)
                if (!tp.hpbx) {
                  tp.hpbx = [];
                }
                tp.hpbx.push(hp);
              }
            })
          })


          let result = teamsProfiles.filter(tp => tp.hpbx);
          let hpbxOnlyUser = res.data.filter(hp => {
            for (let tp of result) {
              for (let id of tp.hpbx) {
                if (id.username === hp.username) {
                  return false;
                }
              }

            }
            return true;
          });

          hpbxOnlyUser = hpbxOnlyUser.map(h => {
            return { hpbx: [h], displayName: h.username }
          })

          if (result.length) {

            dispatch(updateUsers(result));
            dispatch(updateHPBXOnlyUsers(hpbxOnlyUser))
            //stop the previous poller and restart it with the new results
            PollerService.restartPresencePoller();

            getGroups();
            getContacts();
          }
          else {
            console.log("no profiles - potentially still loading...")
          }



        })
        .catch(err => {
          console.log("GOT BACK AN ERROR:", err)
          //setHPBXMessage("Couldn't find other people in hpbx")
        });
    }

    getHPBXPeople();
  }, [teamsProfiles]);






  async function getGroups() {


    HPBXService.getGroups()
      .then(res => {
        let data = res.data.data;
        console.log(data)
        if (data.length) {
          dispatch(updateGroups(data));
        }
      })
      .catch(err => {
        console.log("GOT BACK AN ERROR:", err)
        //setHPBXMessage("Couldn't find other people in hpbx")
      });
  }

  async function getContacts() {


    HPBXService.getContacts()
      .then(res => {
        let data = res.data.data;
        if (data.length) {
          dispatch(updateContacts(data));
        }
      })
      .catch(err => {
        console.log("GOT BACK AN ERROR:", err)
      });
  }



  async function getHPBXLink(msUserProfile) {
    //setHPBXMessage("Please wait... Looking for a linked account...");
    HPBXService.getHPBXLink()
      .then(res => {
        console.log("GOT BACK LINK ::", res);
        let hpbxId = res.data;
        dispatch(updateUserProfile({ ...msUserProfile, hpbx: hpbxId }));
      })
      .catch(err => {
        console.log("GOT BACK AN ERROR:", err)
        dispatch(updateUserProfile({ ...msUserProfile }));
        setHPBXLoading(false);
        //Todo Set Error
        setError("Unable to find matching HPBX account, please check Teams Provisioner and Users in HPBX");

      });
  }


  return (

    <Router>
      <main >
        <div className="" style={{ "overflow-x": "hidden" }}>
          <div className="row">
            <LeftRail></LeftRail>
            <div className="col-sx-12 col-md-8 col-lg-9 col-xxl-10">

              {(error) ? <Alert
                content={error}
                danger
                header="Error"
                visible
              /> :
                (loading || hpbxLoading) ? (
                  <Loader style={{ margin: 100 }} />
                ) : (
                  <>
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/termsofuse" component={TermsOfUse} />
                    <Route exact path="/mailbox" component={Voicemail} />
                    <Route exact path="/messaging" component={Messaging} />
                    <Route exact path="/" component={Main} />
                  </>
                )}
            </div>
          </div>
        </div>
      </main>
    </Router >
  );
}
