import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Button, Flex, FlexItem, Segment, Table, Dropdown, Popup, Loader, Text, Dialog } from "@fluentui/react-northstar";
import { RetryIcon, CallIcon } from '@fluentui/react-icons-northstar'
import HPBXService from "../services/HPBXService";
import format from 'date-fns/format'

import { CallbackRow } from "./CallbackRow";

export const Callbacks = () => {

    const callbacks = useSelector(state => state.callbacks);

    //helper for determining if we should render the calls table
    let hasCalls = false
    for (let server in callbacks) {
        if (callbacks[server].length) {
            hasCalls = true;
        }
    }

    const [open, setOpen] = useState(false);
    const [popupContent, setPopupContent] = useState(<></>);
    const myPresence = useSelector(state => state.user.presence);


    const closePopup = () => {
        setOpen(false)
    }

    const header = {
        items: ['Time', 'Number', 'Last Handled by', 'Callback', 'Complete'],
    }
    let rows = buildRows();

    //mount / unmount
    useEffect(() => {
        rows = buildRows();
    }, [callbacks]);

    const callback = async (server, id) => {


        //check your presence.
        let canCallback = true;
        if (myPresence) {
            switch (myPresence.availability) {
                case "Offline":
                case "Away":
                case "Busy":
                case "DoNotDisturb":
                    canCallback = false;
                    break;
                default:
                    canCallback = true;
                    break;
            }
        }

        if (canCallback) {

            HPBXService.callback(server, id)
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    if (err.response.status == 409) {
                        setPopupContent(
                            <Flex gap="gap.small">
                                <Flex column>
                                    <Text content="Someone else is attempting this callback, if you still need to call this person back please wait 1 minute and try again" />
                                </Flex>
                            </Flex>
                        )
                        setOpen(true);
                    }
                    if (err.response.status == 404) {
                        setPopupContent(
                            <Flex gap="gap.small">
                                <Flex column>
                                    <Text content="The call has already been handled" />
                                </Flex>
                            </Flex>);
                        setOpen(true);
                    }
                });
        }
        else {
            setPopupContent(
                <Flex gap="gap.small">
                    <Flex column>
                        <Text content="Please set your presence as available to make this call and try again" />
                    </Flex>
                </Flex>
            )
            setOpen(true);
        }
    }

    const complete = async (server, id) => {

        HPBXService.completeCallback(server, id)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            });
    }


    function buildRows() {

        let rows = [];

        for (let server in callbacks) {

            rows.push(callbacks[server].map(c => {
                return {
                    Key: c.callid,
                    items: [
                        format(new Date(c.time * 1000), "hh:mma "),
                        c.number,
                        (c.handled_by || "n/a"),
                        <button type="button" class="btn btn-outline-success" onClick={() => callback(server, c.id)}>{<CallIcon />}</button >,
                        <button type="button" class="btn btn-outline-success" onClick={() => complete(server, c.id)}>Complete</button >
                    ]
                };
            }))
        }

        return rows.flat();
    }

    function buildMobileRows() {
        let rows = [];

        for (let server in callbacks) {

            rows.push(callbacks[server].map(c => {
                return <CallbackRow call={c} callback={callback} complete={complete} server={server}></CallbackRow>
            }))
        }

        return rows;
    }

    return (
        <>
            <Dialog
                open={open}
                onOpenChange={(e, { open }) => setOpen(open)}
                confirmButton="OK"
                onConfirm={closePopup}
                content={{
                    content: popupContent,
                    'aria-label': 'Call Pickup Result',
                }}
                trapFocus
            />
            {hasCalls ?
                <>
                    <div className="d-none d-md-inline ">
                        <Table
                            header={header}
                            rows={rows}
                            aria-label="Callbacks"
                        /> </div>
                    <div className="d-inline d-md-none ">
                        {
                            buildMobileRows()
                        }
                    </div>
                </> :
                <Segment className="text-center">There are currently no callbacks</Segment>
            }



        </>
    );
}
