import React from "react";
import "./StatTiles.css";


export const StatTiles = ({ callers_waiting, avg_wait_time, parked_calls }) => {

    let avgDisplay = `${avg_wait_time} seconds`;

    if (avg_wait_time > 60) {
        avgDisplay = `${Math.floor(avg_wait_time / 60)} mins ${avg_wait_time % 60} secs`;
    }


    return (
        <div className="row d-none d-md-block">
            <div className="d-flex flex-wrap justify-content-evenly">

                <div class="col-xs-6 col-md-3">
                    <div class="panel panel-dark text-center" >
                        <h2>{callers_waiting}</h2>
                        <h3>Callers Waiting</h3>
                    </div>
                </div>

                <div class="col-xs-6 col-md-3">
                    <div class="panel panel-blue text-center" >
                        <h2>{avgDisplay}</h2>
                        <h3>Average Wait</h3>
                    </div>
                </div>

                <div class="col-xs-6 col-md-3">
                    <div class="panel panel-purple text-center" >
                        <h2>{parked_calls}</h2>
                        <h3>Parked Calls</h3>
                    </div>
                </div>


            </div>
        </div>
    );
}