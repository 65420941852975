import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Button, Flex, FlexItem, Segment, Table, Dropdown, Popup, Loader, Text, Dialog } from "@fluentui/react-northstar";
import { RetryIcon, EditIcon } from '@fluentui/react-icons-northstar'
import HPBXService from "../services/HPBXService";
import format from 'date-fns/format'

import { CallHistoryRow } from "./CallHistoryRow";

export const CallHistory = () => {

    const profile = useSelector(state => state.user.userProfile);
    const [callHistory, setCallHistory] = useState([]);
    const [outcomes, setOutcomes] = useState({});
    const queues = useSelector(state => state.hpbx.queues);
    const queueLog = useSelector(state => state.queue.queueLog);

    const [outcome, setOutcome] = useState({});
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [dialog, setDialog] = useState(<Dialog></Dialog>);

    const [selectedCall, setSelectedCall] = useState({});

    function setOutcomeDropDown(outcome) {
        for (let s in outcomes) {
            let o = outcomes[s].find(o => o.description === outcome);
            if (o) {
                setOutcome(o);
                break;
            }
        }

    }


    function wrapCall(call) {
        console.log("Wrapping Call", call, outcome)
        if (outcome.id) {
            setLoading(true);

            HPBXService.wrapCall(call.server, call.callid, call.queue, outcome.id)
                .then(res => {
                    setLoading(false);
                    closePopup();

                    getRecentCalls();
                })
                .catch(err => {
                    console.log("GOT BACK AN ERROR:", err)
                    setLoading(false);
                });
        }
    }

    const closePopup = () => {
        console.log("closing popup");
        setOpen(false)
        setDialog(<Dialog></Dialog>);
    }

    const header = {
        items: ['Date', 'Queue', 'Caller', 'Outcome', <button type="button" className="btn btn-outline-secondary" onClick={getRecentCalls}>{<RetryIcon />}</button>],
    }


    //mount / unmount
    useEffect(() => {
        let outcomes = {};

        async function getOutcomes() {

            if (profile.hpbx && profile.hpbx.length) {
                for (let id of profile.hpbx) {

                    try {
                        let res = await HPBXService.getOutcomes(id.server)
                        outcomes[id.server] = res.data.data;
                    }
                    catch (err) {
                        console.log("GOT BACK AN ERROR:", err)
                    }
                }
                setOutcomes(outcomes)
            }
        }

        getOutcomes();

    }, []);

    useEffect(() => { getRecentCalls() }, [outcomes]);

    async function getRecentCalls() {

        HPBXService.getRecentCalls()
            .then(res => {
                let calls = res.data.data.completedCalls;
                res.data.data.wrappedCalls.forEach(w => {
                    let c = calls.find(c => w.callid === c.callid);
                    if (c && outcomes[c.server]) {
                        c.outcome = outcomes[c.server].find(o => o.id == w.outcome);
                    }
                }
                )

                setCallHistory(calls)
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)
                //setHPBXMessage("Couldn't Load recent calls.")
            });
    }

    function getQueueName(queue) {
        let q = queues.find(q => q.id == queue);
        if (q) {
            return q.name;
        }
        else {
            return "N/A";
        }
    }


    const popupContent = (call) => (loading) ?
        (
            <Loader style={{ margin: 100 }} />
        )
        :
        (
            <>
                <Flex gap="gap.small">
                    <Flex column>
                        <Text content={"Date: " + format(new Date(call.time * 1000), "hh:mma ")} />
                        <Text content={"Queue: " + getQueueName(call.queue)} />
                        <Text content={"Caller ID: " + call.callerid} size="bold" />
                    </Flex>
                </Flex>

                <Dropdown
                    items={outcomes[call.server] ? outcomes[call.server].map(o => o.description) : []}
                    placeholder="Outcome"
                    onChange={(e, selected) => setOutcomeDropDown(selected.value)}
                />
                <br />
                <Flex gap="gap.small">
                    <Flex.Item push>
                        <Button content="Cancel" onClick={closePopup} />
                    </Flex.Item>
                    <Button onClick={() => wrapCall(call)} primary content="Save" />
                </Flex>
            </>
        );

    function buildRows() {
        return callHistory.map(c => {

            return {
                Key: c.callid,
                items: [
                    format(new Date(c.time * 1000), "hh:mma "),
                    getQueueName(c.queue),
                    c.callerid,
                    c.outcome?.description || '',
                    <button type="button" class="btn btn-outline-secondary" onClick={() => openDialog(c)} > {< EditIcon />}</button >
                ]
            };
        })
    }

    useEffect(() => {

        if (selectedCall && Object.keys(selectedCall).length !== 0) {
            let d = (<Dialog
                open={open}
                content={{
                    content: popupContent(selectedCall),
                    'aria-label': 'Outcome',
                }}
                trapFocus
            />);
            setDialog(d);
        }
        else {
            setDialog(<></>);
        }
    }, [selectedCall, loading, open, outcome])


    function openDialog(call) {
        setSelectedCall(call);
        setOpen(true);
    }

    let rows = buildRows();

    return (
        <>
            {dialog}

            <div className="d-none d-md-inline ">

                <Table
                    header={header}
                    rows={rows}
                    aria-label="Call History"
                />
            </div>

            <div className="d-inline d-md-none ">
                {callHistory.map(c => {
                    return <CallHistoryRow call={c} openDialog={openDialog} ></CallHistoryRow>
                })}
            </div>
        </>
    );
}
