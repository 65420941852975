import React, { useState, useEffect } from "react";
import VMCard from "./VMCard";

import HPBXService from "../services/HPBXService";

const Voicemail = () => {
    const [mail, setMail] = useState([]);

    useEffect(() => {
        console.log("Starting");
        getHPBXMailbox()
    }, []);


    async function getHPBXMailbox() {

        HPBXService.getHPBXMailbox()
            .then(res => {
                console.log("GOT BACK ::", res);
                console.log(res.data.data)
                setMail(res.data.data);
            })
            .catch(err => {
                console.log("GOT BACK AN ERROR:", err)
            });
    }

    return (
        <div>
            <h1>Voicemail</h1>
            {mail &&
                (
                    <div className="m-1">
                        <div className="row"><h4>Your mail:</h4></div>
                        <div className="row">
                            {mail.map(m => {
                                return <div key={m.path} className="col-12"><VMCard {...m} /></div>;
                            })}
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Voicemail

