import { UPDATE_CALLBACKS } from '../actions';


const initialState = {
    //parkedCalls: []
}

export default function callbackReducer(state, action) {
    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case UPDATE_CALLBACKS:

            if (!state[action.update.server]) {
                state[action.update.server] = [];
            }

            state[action.update.server] = [...action.update.callbacks];

            state = JSON.parse(JSON.stringify(state));

            break;
        default:
            break;
    }

    return state
}