import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Text, Card, Flex, Segment, Status, Dialog } from "@fluentui/react-northstar";
import { ContactCardIcon, CallIcon, CallParkingIcon, PhoneArrowIcon, CallEndIcon } from '@fluentui/react-icons-northstar'

import util from "./lib/util";

export const AnsweredCallRow = ({ server, call, parkFn, xferFn, hangupFn }) => {

    let talkTime = Math.floor((new Date().getTime() - (call.answered * 1000)) / 1000);

    let talkTimeDisplay = util.formatSeconds(talkTime);

    let num = '';

    if (call.bridged_callid) {
        if (call.bridged_dtype === 'external') {
            num = call.bridged_dnumber;
        }
        else {
            num = call.bridged_callerid_external;
        }
    }
    else {
        if (call.dtype === 'external') {
            num = call.dnumber;
        }
        else {
            num = call.callerid_external;
        }
    }

    return (
        <>
            <div className="row m-3 d-block d-md-none">
                {call && (



                    <Segment>
                        <Flex gap="gap.medium" space="around">

                            <h4><CallIcon />{num}</h4>

                            <h4><i class="bi bi-clock"></i>{talkTimeDisplay}</h4>
                        </Flex>
                        {parkFn &&
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-primary p-3 m-3" onClick={() => parkFn(server, call)}>{<CallParkingIcon />}</button>
                            </div>}
                        {
                            xferFn &&
                            <div class="d-grid gap-2">
                                {
                                    <button type="button" class="btn btn-info p-3 m-3" onClick={() => xferFn(server, call)}>{<PhoneArrowIcon />}</button>
                                }
                            </div>

                        }
                        {hangupFn &&
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-danger p-3 m-3" onClick={() => hangupFn(server, call)}>{<CallEndIcon />}</button>
                            </div>}
                    </Segment>


                )}
            </div>
        </>
    );
}
