import { UPDATE_RAIL } from '../actions';


const initialState = {
    menu: "Team"
}

export default function leftRailReducer(state, action) {
    //initialize state if its undef
    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case UPDATE_RAIL:
            state.menu = action.payload;
            break;
        default:
            break;
    }

    return state
}