import React from "react";
import { Provider, teamsTheme } from "@fluentui/react-northstar";
import { useTeamsFx } from "./lib/useTeamsFx";
import Init from "./Init";

import { Loading } from "./Loading"


export default function InitFx() {
    const { theme, loading, error, isInTeams } = useTeamsFx();

    return (
        loading ? <Loading></Loading> :
            error ? <h1>error</h1> : <Provider theme={theme || teamsTheme} ><Init /></Provider>

    )
}
