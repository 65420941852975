import React, { useState } from "react";
import { Text, Dialog, Flex, Button } from "@fluentui/react-northstar";
import { ChatIcon, CallIcon, EditIcon } from '@fluentui/react-icons-northstar';
import { useSelector } from 'react-redux';
import HPBXService from "../services/HPBXService";
import { CreateSMSContact } from "./CreateSMSContact";
import { CreateContact } from "./CreateContact";


export const ContactRailItem = ({ server, contact }) => {

    const [open, setOpen] = useState(false);
    const [createContactOpen, setCreateContactOpen] = useState(false);
    const [popupContent, setPopupContent] = useState(<></>);


    const [smsDialogOpen, setSMSDialogOpen] = useState(false);
    const [smsDestination, setSMSDestination] = useState("");

    const myPresence = useSelector(state => state.user.presence);

    const closePopup = () => {
        setOpen(false)
    }

    const startSMSWith = async (cnumber) => {

        console.log("startSMSWith::", cnumber);

        setSMSDestination(cnumber);
        setSMSDialogOpen(true);
    }

    const callContact = async (cnumber) => {
        //check your presence.
        let canCallback = true;
        if (myPresence) {
            switch (myPresence.availability) {
                case "Offline":
                case "Away":
                case "Busy":
                case "DoNotDisturb":
                    canCallback = false;
                    break;
                default:
                    canCallback = true;
                    break;
            }
        }

        if (canCallback) {

            HPBXService.makeCall(server, cnumber.replace(/^0(\d{9})/, "61$1"), "forward")
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.log("GOT BACK AN ERROR:", err)
                });
        }
        else {
            setPopupContent(
                <Flex gap="gap.small">
                    <Flex column>
                        <Text content="Please set your presence as available to make this call and try again" />
                    </Flex>
                </Flex>
            )
            setOpen(true);
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onOpenChange={(e, { open }) => setOpen(open)}
                confirmButton="OK"
                onConfirm={closePopup}
                content={{
                    content: popupContent,
                    'aria-label': 'Call Result',
                }}
                trapFocus
            />
            <CreateSMSContact open={smsDialogOpen} setOpen={setSMSDialogOpen} destination={smsDestination} ></CreateSMSContact>
            <CreateContact open={createContactOpen} setOpen={setCreateContactOpen} contact={contact}></CreateContact>
            <div className="row p-2 rail-item" >
                {contact && (
                    <>
                        <div class="d-flex flex-row align-items-center">
                            <Text content={contact.display_name} size="small" weight="bold" />

                            <Button icon={<EditIcon />} text iconOnly title="Edit" onClick={() => { setCreateContactOpen(true) }} />
                        </div>
                        {contact.office_number &&
                            <div class="d-flex flex-row align-items-center">
                                <Text content={contact.office_number} size="small" />
                                <div class="ms-auto p-2">
                                    <button type="button" class="btn btn-outline-success" onClick={() => callContact(contact.office_number)}>{<CallIcon />}</button >
                                </div>
                            </div>
                        }
                        {contact.mobile_number &&
                            <div class="d-flex flex-row align-items-center">
                                <Text content={contact.mobile_number} size="small" />
                                <div class="ms-auto p-2">
                                    <button type="button" class="btn btn-outline-success" onClick={() => callContact(contact.mobile_number)}>{<CallIcon />}</button >
                                    <button type="button" class="btn btn-outline-success ms-2" onClick={() => startSMSWith(contact.mobile_number)}><ChatIcon /></button>
                                </div>
                            </div>
                        }
                    </>
                )
                }
            </div >
        </>
    );
}
